.container {
  background-color: #ccc;
}
.margin_top_enabled {
  margin-top: 75px;
}
.resource_order {
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  /* padding-top: 80px; */
  width: 100%;
  background-color: #fff;
}
.resource_order .resource_form {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.resource_order .row.form_row {
  margin: 0;
}
.resource_order .col {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}
.resource_order .inputs_col {
  width: 100%;
}
.resource_order .inputs_col:first-child .inputs_head {
  padding-left: 0;
  padding-right: 0;
}
.resource_order .inputs_head {
  font-weight: 700;
  margin-bottom: 14px;
  padding-left: 6px;
  padding-right: 6px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.resource_order .inputs_items {
  display: flex;
  margin-right: 6px;
  margin-left: 6px;
  flex-direction: column;
}
.resource_order .inputs_col:first-child .inputs_items {
  margin-left: 0;
}
.resource_order .inputs_col:last-child .inputs_col .inputs_items {
  margin-left: 6px;
}
.resource_order .inputs_col:last-child .inputs_items {
  margin-right: 0;
}
.resource_order textarea {
  resize: none;
  min-height: 158px;
}
.inputs_table {
  width: 100%;
  border-color: #dadada;
}
.inputs_table td {
  /* padding: 10px; */
  min-height: 20px;
}

.action_bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cancel_btn_margin {
  margin-right: 10px;
}
.add_crews_btn_wrap {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.empty_placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 200px;
}
@media (min-width: 768px) {
  .resource_order .col_md_6 {
    min-width: 50%;
  }
}
@media (min-width: 1299px) {
  .resource_order .col_lg_9 {
    min-width: 75%;
  }
  .resource_order .col_lg_6 {
    min-width: 50%;
  }
}

@media screen and (max-width: 1399px) {
  .resource_order .col {
    flex-wrap: wrap;
  }
  .resource_order .inputs_col {
    flex: 1;
    min-width: 33.33%;
  }
}
@media screen and (max-width: 1199px) {
  .resource_order .inputs_table {
    width: 1000px;
  }
  .resource_order .inputs_col_table {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 992px) {
  .resource_order .col {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .resource_order {
    padding: 15px;
  }
  .resource_order .doc_info .toggle-btn-grp {
    top: 32px;
  }
}

@media screen and (max-width: 600px) {
  .resource_order .inputs_table td {
    display: table-cell;
    width: auto;
  }
}
@media (max-width: 481px) {
  .resource_order .inputs_items {
    margin-right: 0px;
    margin-left: 0px;
  }
  .onboarding-docs .form-top-buttons .button {
    padding: 6px 5px;
  }
}

.resource_order .resource_order_rs_list {
  z-index: 7;
  position: absolute;
  background: #fff;
  max-height: 120px;
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  margin-top: -22px;
  border-bottom: 1px solid #d0d3d4 !important;
  overflow: auto;
}

.resource_order .item {
  padding: 15px 10px;
  border: 1px solid #d0d3d4;
  border-bottom: 0;
  cursor: pointer;
  font-size: 80%;
}
.resource_order .inputs_table ::placeholder {
  font-size: 1rem;
}
.resource_order .inputs_table td {
  font-size: 1.2rem;
  padding: 6px;
}

.resource_order .inputs_table input {
  font-size: 1.1rem;
  padding: 6px 6px;
}

.resource_order ::placeholder {
  font-size: 1.3rem;
}

.resource_order input {
  height: 43px;
}

.error {
  border: 1px solid rgba(223, 70, 67, 0.5);
}
/* Feb */

.created_by {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
