.notification_container .inner_head {
  min-height: 56px;
}
.notification_container .inner_head input {
  float: right;
  width: 100%;
  max-width: 260px;
  margin-right: 20px;
  margin-bottom: 0px;
  padding: 10px;
}
.notification_container .inner_head select {
  float: right;
  width: 100%;
  max-width: 260px;
  margin-bottom: 0px;
}

.notification_container .notfication-loader {
  width: 100%;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.notification_container .loader-wrapper {
  float: left;
  display: inline;
  width: 20px;
  height: 20px;
  margin: 10px;
}
.notification_container .scrollLoader {
  position: absolute;
  width: 100%;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  bottom: 15px;
}

.notification_list .list_header h2 {
  margin: 0;
  font-size: 14px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.notification_list a.notif_name {
  color: #3f7e39;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
div#notification_list {
  padding-top: 20px;
  min-height: 420px;
}
.notification_list .list_item {
  padding: 15px 10px;
}
.marg-right-20 {
  margin-right: 20px;
}
.emp_verify_wrap .formField h4 {
  font-size: 14px;
}
.txt-capitalize {
  text-transform: capitalize;
}
.notification_list a {
  color: #202020;
}
.notification_list .basic_info .left .name,
.notification_list .basic_info .left .notif_name {
  text-transform: capitalize;
}
.notification_container {
  margin-top: 80px;
}

@media only screen and (max-width: 992px) {
  .notification_container .inner_head input {
    max-width: 230px;
  }
  .notification_container .inner_head select {
    max-width: 230px;
  }
}

@media only screen and (max-width: 610px) {
  .notification_container .inner_head input {
    float: left;
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
    padding: 10px;
  }
  .notification_container .inner_head select {
    float: left;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .notification_container .loader-wrapper {
    float: left;
    display: inline;
    width: 100%;
    height: 20px;
    margin: 0px 0px 10px;
  }
}
