.resource_type .logo_head {
  text-align: center;
}
.top_head {
  padding: 20px 0 10px 0;
  text-align: center;
  position: relative;
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid #d8d8d8;
}
.top_head span {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.top_head .back {
  position: absolute;
  text-decoration: none;
  bottom: 10px;
  left: 0;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  color: #202020;
  cursor: pointer;
}
.top_head .title {
  font-size: 16px;
}
.top_head .backToLogin {
  position: absolute;
  text-decoration: none;
  bottom: 10px;
  right: 0;
  font-weight: bold !important;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  color: #202020 !important;
  cursor: pointer;
}
.completeProfile {
  margin-bottom: 2rem;
}
.profile_pic_div {
  padding: 30px 0;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 60px !important;
  width: 80%;
  padding-bottom: 0;
  margin: 0 auto;
}
.signup-contentarea.prof_pic {
  position: relative;
  border: 0;
  margin-bottom: 0 !important;
}
.signup-contentarea {
  width: 60%;
  margin: 0 auto;
}
.signup-contentarea .position-select {
  max-width: 353px;
  margin: auto;
}
/* .position-select span {
    background: #3F7E39;
    display: block;
    color: #fff;
    padding: 12px 15px;
    text-decoration: none;
    margin: 15px 0;
    position: relative;
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
}
.position-select span:after{
    content: ">";
    position: absolute;
    right: 12px;
}
.position-select span:hover{
	cursor: pointer;
} */
div#informationWrapA {
  max-width: 493px;
  margin: 0 auto;
}
.txt {
  width: 100%;
  height: 47px;
  padding: 0 10px;
  margin: 0 0 12px;
  border: 1px solid #d0d3d4;
  font:
    400 14px/15px "Proxima Nova",
    Georgia,
    sans-serif;
  color: #9c9c9c;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
}
.half1 {
  width: 48%;
}
.half2 {
  width: 48%;
  float: right;
}
.txt.txt-country-code {
  width: 12%;
  text-align: center;
  margin-right: 3%;
  float: left;
  padding: 4px;
}
.txt.txt-phone {
  width: 85%;
}
span.wpcf7-list-item {
  margin: 0 0 16px 0 !important;
  float: left;
  width: 100%;
}
.check-list-1 input {
  width: auto !important;
  height: auto !important;
  font-size: 18px !important;
  vertical-align: middle;
  margin: -2px 8px 0 0 !important;
}
.check-list-1 span {
  font-size: 15px !important;
  font-weight: normal !important;
  line-height: 22px;
}
input.button.nextBtn {
  float: right;
}
#informationWrapA label {
  display: block;
  margin-top: 5px;
  width: 100%;
}
#informationWrapA input#chkSameAddress {
  width: auto;
}
.half_div {
  width: 50%;
  float: left;
}
.half_div.fname {
  padding-right: 7.5px;
}
.half_div.lname {
  padding-left: 7.5px;
}
.half_div input {
  width: 100%;
}
.city_div {
  width: 48%;
  float: left;
}
.city_div input {
  width: 100%;
}
.addressData {
  float: left;
  width: 50%;
}
.addressData input {
  width: 100%;
}
.addressData:first-child {
  padding-right: 7.5px;
}
.addressData:last-child {
  padding-left: 7.5px;
}
.special-h-1,
.special-h-2 {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
#informationWrapA h3 {
  font-size: 16px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}

input#mailingAddress[readonly],
input#mailingCity[readonly],
input#mailingState[readonly],
input#mailingZip[readonly] {
  background: #f7f9fa;
}
#informationWrapA .react-datepicker-wrapper,
#informationWrapA .react-datepicker__input-container {
  width: 100%;
}

.prof_pic.upload_or_drag {
  width: 50%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}
.signup-contentarea.prof_pic.upload_or_drag .notes {
  margin-bottom: 25px;
}
.readonly {
  cursor: default;
}

.profile_pic_div .resource_upload_div {
  margin: 0 auto 60px !important;
  float: none !important;
}
.profile_pic_div .prof_pic.upload_or_drag p {
  margin-bottom: 0px;
  padding: 25px 0;
  cursor: pointer;
}
.profile_pic_div .prof_pic.upload_or_drag {
  padding: 0;
  min-height: 70px;
}
.register_popup .button_list {
  margin-top: 8px;
}
.profile_pic_div .prof_pic.upload_or_drag .ReactCrop {
  margin-bottom: 15px;
  max-width: 60%;
  cursor: default;
}
.resource_type .profile_pic_div p.notes {
  text-align: center;
}
