.assignment-progress svg {
  display: block;
  margin: auto;
  float: initial !important;
}
.assignment-progress span {
  /* width: 100%;
  float: left;
  text-align: center;
  font-weight: 600;
  margin-top: 5px; */
}
.assignment-progress span img {
  position: absolute;
  margin-left: 5px;
  margin-top: 2px;
}
.form-control {
  z-index: 5;
}
.form-group {
  z-index: 5;
}
/* .assignment_stats-details .total_crew{
  width: 15%;
  display: inline-block;
  float: left;
} */
.assignment_stats-details .total_crew {
  float: left;
  border-right: 1px solid #cccccc;
  min-height: 90px;
  margin-right: 5%;
  flex: 0 0 30%;
  display: flex;
  padding-right: 20px;
  flex-direction: column;
  align-items: flex-end;
}
.assignment_stats-details .total_crew .crew_no {
  font-size: 44px;
  font-weight: 600;
  display: block;
  text-align: center;
  width: 100%;
}
.assignment_stats .inventory_progress .CircularProgressbar {
  margin-top: 0px;
  margin-bottom: 0px;
}
.assignment_stats-details .total_crew span {
  font-weight: 600;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.assignment_stats-details {
  padding: 40px 30px !important;
}
.assignment-progress {
  display: flex;
  flex-direction: column;
  flex: auto;
  margin-right: 2%;
}
.assignment_stats {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}
.assignment-status-outer-form {
  margin-top: 60px;
}
.assignment-status-outer-form .react-datepicker-wrapper {
  width: 100%;
}
.assignment-status-outer-form
  .react-datepicker-wrapper
  .react-datepicker__input-container {
  width: 100%;
}
.assignment-status-outer-form input,
.assignment-status-outer-form select {
  border-radius: 3px;
  height: 36px;
  border: 1px solid #d0d3d4;
}
.run-report {
  float: right;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
.datepicker-image {
  position: absolute;
  width: 12px;
  z-index: 1;
  right: 25px;
  top: 8px;
}
.datepicker-image img {
  width: 100%;
}
@media screen and (max-width: 1366px) {
  .inventory_progress {
    max-width: 18%;
  }
}
@media screen and (max-width: 1200px) {
  .assignment-progress {
    width: 20% !important;
  }
}
@media screen and (max-width: 1024px) {
  .assignment_stats-details .total_crew {
    margin-right: 2%;
  }
}
@media screen and (max-width: 991px) {
  .inventory_progress {
    max-width: 30%;
    float: left;
  }
}
@media screen and (max-width: 768px) {
  .assignment_stats-details .assignment_stats {
    display: block;
  }
  .assignment_stats-details .total_crew {
    width: 100%;
    border-right: none;
    margin-right: 0px;
    padding-right: 0px;
  }
  .assignment-progress {
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 20px;
  }
  .assignment-status-outer-form {
    float: left;
    width: 100%;
  }
}

.status-icon-block {
}

.section-wrapper {
  display: inline;
  cursor: pointer;
}

.section-wrapper-main {
  display: block;
  width: 100%;
  text-align: center;
  padding: 6px 0 0;
}

@media screen and (max-width: 1700px) {
  .percentage-block,
  .status-icon-block {
    display: block;
  }
}
.right-align {
  text-align: center;
  padding: 0 20px;
}
