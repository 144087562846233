.signatureWrap {
  border: 1px solid #d5d8d9;
  border-radius: 3px;
  padding: 30px 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 480px;
  text-align: center;
  min-height: 145px;
}

.signatureWrapError {
  border: 1px solid rgba(223, 70, 67, 0.5);
}

.signatureWrap .label {
  margin-top: 5px;
  margin-bottom: 20px;
}

.signatureWrap img {
  max-width: 100%;
}

.signatureWrap p.sign_buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.signatureWrap p.sign_buttons button {
  background-color: #3f7e39;
  color: #fff;
  margin-left: 15px;
}
