@media (max-width: 767px) {
  .msg-header,
  .msg-messageheader h3.title span {
    display: none !important;
  }
  .row.msg.msg-header.dashb-header {
    display: none;
  }
  .msg-heading {
    display: none !important;
  }
  .row.msg .col-sm-8 {
    display: none;
  }
  .is-admin-header {
    padding-left: 0;
  }
  .chat-active > .container-fluid {
    padding: 0;
    box-sizing: border-box;
  }
  .chat-active .left_sidebar {
    /* display: none; */
  }
  .chat-active .right_sidebar {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .chat-active .row.outer_container {
    margin-left: 0;
    margin-right: 0;
  }

  .chat-active .top_bar {
    margin: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #fff;
  }

  /*admin check*/
  .is-admin-header .pretty.p-switch .state:before {
    width: 22px;
    top: 2px;
    height: 8px;
  }
  .is-admin-header .pretty.p-switch .state label:after {
    width: 16px;
    height: 16px;
  }
  .is-admin-header .pretty {
    padding-top: 10px;
  }
  /*admin check*/

  #app .outer {
    padding-top: 50px !important;
    margin-top: 0 !important;
  }
  #app .outerMostContainer {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  #app .chat-active .outerMostContainer {
    margin-top: 0 !important;
    padding-top: 60px !important;
  }

  .msg-hide {
    display: none !important;
  }
  .msg-name {
    font-size: 14px;
  }
  .msg-user-list {
    max-width: 100%;
    background: #fff;
    border: none;
    position: absolute;
    z-index: 7;
    top: 0;
    bottom: 0;
    margin-top: 5px;
  }
  .row.msg {
    flex-direction: column;
  }
  .col-msg {
    padding: 0;
    width: 100%;
    float: left;
  }
  .msg-messages-list .col-msg {
    padding-top: 68px;
  }

  .col-msg .msg-users {
    padding: 13px 18px;
  }
  .msg-lists {
    min-height: calc(100vh - 173px);
    max-height: calc(100vh - 173px);
  }
  .msg-modify {
    position: absolute;
    top: 16px;
    right: 18px;
  }
  .msg-users {
    padding: 13px 18px;
  }

  .msg-section-title {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  .time {
    font-size: 12px;
    margin-top: 3px;
  }
  .msg-preview,
  .msg-full {
    font-size: 12px;
  }

  .msg-user-pic {
    width: 36px;
    height: 36px;
  }
  .msg-user-pic.has-message:after {
    width: 46px;
    height: 46px;
    top: -5px;
    left: -5px;
  }
  .msg-userlist-search {
    border-bottom: none;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    background: #eef0f2;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .msg-userlist-search input {
    border: 1px solid #979797;
    max-height: 38px;
    min-height: 38px;
    padding: 0px 35px;
    border-radius: 6px;
    margin-right: 10px;
    background-position: 12px center;
    background-color: #fff;
    width: 100%;
  }
  .msg-userlist-search input:focus {
    border: 1px solid #407f38;
    padding: 0px 10px;
    background-image: none;
  }
  button.mob-createnewBtn {
    background: transparent;
    display: block;
    border: none;
    outline: none;
  }
  .search_inputs {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .search_inputs .close-inputBtn {
    top: 16px;
    right: 47px;
    transform: none;
  }

  .search_inputs input.admin_mode + .close-inputBtn {
    right: 20px;
  }
  .add-memberform .form-group {
    position: relative;
  }
  .add-memberform .close-inputBtn {
    display: block;
    top: 10px;
    right: 10px;
  }

  .msg-messages {
    width: 100%;
  }
  .msg-messages-list {
    padding-left: 0px;
    padding-right: 0px;
    max-height: calc(100vh - 190px);
  }
  .msg-col.msg-messages .msg-mob-messageheader {
    position: fixed;
    right: 15px;
    left: 15px;
    padding: 7px 15px;
    z-index: 8;
  }
  .msg-group-info.opened {
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    right: -100%;
    left: 0;
    position: relative;
    animation: none;
    animation: gi-open_mob 0.3s forwards;
  }

  @keyframes gi-open_mob {
    0% {
      right: -100%;
    }
    100% {
      right: 0%;
    }
  }

  .group-info-header {
    display: none;
  }
  .msg-added-member .msg-messageData .msg-name {
    display: none;
  }
  .msg-added-member .msg-messageData {
    padding: 0;
  }
  h3.membercount {
    font-size: 12px;
  }
  h3.membercount span {
    font-size: 9px;
    width: 16px;
    height: 16px;
  }
  h3.membercount {
    margin-bottom: 10px;
  }
  h3.group-title {
    font-size: 16px;
    font-weight: 600;
  }

  h3.group-title {
    font-size: 10px;
    font-weight: 600;
  }
  a.groupmember-edit,
  a.groupname-edit {
    font-size: 14px;
  }
  a.groupname-edit {
    margin-left: 5px;
  }
  .group-member-added {
    display: none;
  }
  .group-info-header,
  .group-titles,
  h3.membercount,
  .msg-addUser,
  .group-member-list .msg-users {
    padding-left: 18px;
    padding-right: 18px;
  }
  .msg-addUser {
    padding: 0 15px;
    padding-bottom: 20px;
  }
  .msg-addUser:after {
    bottom: 0;
  }
  .msg-addUser a img {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }
  a.add {
    font-size: 16px;
  }
  .group-titleEdit .form-control:focus {
    border-color: #407f38;
  }
  .form-control:focus {
    background: #f7f9fa;
  }
  a.removeMember {
    width: 14px;
    height: 14px;
    right: 0px;
    top: 16px;
  }
  a.removeMember img {
    width: 100%;
  }
  .msg-added-member {
    padding: 0;
  }
  .msg-added-member .msg-user-pic {
    width: 36px;
    height: 36px;
  }
  .new-container .group-member-list .msg-users {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #e9e9e9;
  }
  .new-container .group-member-list .msg-users.selected {
    background: none;
  }
  .msg-messageheader {
    display: none;
  }
  .msg-mob-messageheader {
    display: flex;
    padding: 7px 0px;
    background: #eef0f2;
    position: relative;
    flex-direction: row;
    align-items: center;
  }
  .name-col,
  .control-col {
    padding: 0px 15px;
  }
  .control-col {
    margin-left: auto;
  }
  .msg-mob-messageheader .msg-action {
    right: 20px;
  }
  .msg-mob-messageheader .is-admin-header .pretty {
    margin-top: 10px;
    float: right;
  }
  .msg-mob-messageheader .is-admin-header {
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 12px;
  }
  .msg-group-info.opened .msg-mob-messageheader {
    padding: 15px 18px;
  }
  .msg-mob-messageheader h3.title {
    font-size: 16px;
    color: #000;
    float: left;
    padding: 0;
    margin: 2px 0px;
    font-weight: 600;
    line-height: 2;
  }
  .msg-mob-messageheader h3.title i {
    display: none;
  }
  a.msg-admincontrol {
    font-size: 18px;
  }
  a.mob-backbtn {
    float: left;
    display: block;
    margin-right: 13px;
    opacity: 0.6;
    margin-top: 6px;
  }
  .msg-mob-messageheader ul.admincontrols {
    padding: 0;
    margin: 0;
    float: right;
    display: flex;
  }
  .msg-user-pic.has-message .msg-count {
    font-size: 9px;
  }
  .msg-mob-messageheader ul.admincontrols.nav > li {
    display: inline-block;
  }
  .msg-mob-messageheader a.msg-admincontrol {
    padding: 0 10px !important;
    font-size: 13px;
    color: #4d4d4d;
  }
  .msg-mob-messageheader a.msg-admincontrol i {
    font-size: 14px;
  }
  .msg-mob-messageheader li.msg-action_toggler a.msg-admincontrol {
    font-size: 24px !important;
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
    line-height: 7px;
  }
  .msg-messages.group-info-open {
    max-width: 100%;
  }
  .msg-group-info {
    padding: 15px 0;
    padding-top: 0;
    border: none;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 8;
    height: 100vh;
  }
  .group-titles {
    margin-top: 15px;
  }
  a.memberRemove {
    color: #407f38;
  }
  .msg-form {
    padding: 18px;
    position: fixed;
    left: 0;
    right: 0;
    height: 100px;
  }
  .msg-input {
    max-width: 100%;
  }
  a.attach-doc {
    position: absolute;
    border: none;
    margin-right: 0;
    border-radius: 0;
    /* border-bottom: 1px solid #eaeaea; */
    border-top: 1px solid #eaeaea;
    left: 20px;
    height: 44px;
    z-index: 2;
  }
  input.messagesend,
  textarea.messagesend {
    padding-left: 40px;
  }
  .msg-full {
    padding-right: 15px;
  }
  .msg-col {
    height: 100vh;
    max-height: calc(100vh - 57px);
  }
  .msg-col.msg-messages {
    max-height: calc(100vh - 117px);
    position: absolute;
    z-index: 8;
    /* display: none; */
  }
  /* .msg-col.msg-messages.is-admin{
        max-height: calc(100vh - 168px)
    } */
  .row.msg-message-section {
    margin: 0;
  }
  .add-memberform h3.new-title {
    display: none;
  }
  .msg-new h3.membercount {
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
    font-size: 11px;
    text-transform: uppercase;
    color: #9b9b9b;
  }
  form#addNewMember {
    margin-top: 0px;
    padding-top: 14px;
  }

  /* .msg-full.edit-mode {
        position: fixed;
        z-index: 2;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 0;
        width: 100%;
        background: #fff;
    }
    form#editmessage {
        position: absolute;
        bottom: 15px;
        right: 15px;
        left: 15px;
    }
    form#editmessage a.attach-doc{
        background: transparent;
        border: none;
    }
    form#editmessage button.sendBtn{
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
    }*/
  .editButtons {
    display: none;
  }

  .msg-full.edit-mode .editButtons {
    display: block;
  }

  .msg-full.edit-mode .msg-mob-messageheader {
    display: none;

    padding-left: 25px;
    padding-right: 25px;
  }
  .msg-new .new-container {
    padding-top: 12px;
  }
  .msg-new .new-container .group-member-list {
    min-height: calc(100vh - 268px);
    max-height: calc(100vh - 268px);
  }
  .group-name-form {
    min-height: calc(100vh - 188px);
  }

  .group-member-list-scroll {
    min-height: calc(100vh - 360px);
    max-height: calc(100vh - 360px);
  }
  /*show hide for mobile deviecs*/
  .showmb {
    display: block !important;
  }
  .hidemb {
    display: none !important;
  }
}

/* end 767 */

@media screen and (max-width: 1024px) {
  .msg-messageheader h3.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(50% + 25px);
  }
}
@media screen and (max-width: 992px) {
  a.msg-admincontrol {
    padding: 0 5px !important;
  }
  .msg-messageheader h3.title span {
    margin-right: 10px;
  }
  .msg-messageheader h3.title {
    max-width: calc(50% - 5px);
  }

  .msg-new.new .group-member-list-scroll {
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }
  /* .admin-user .msg-new.new .group-member-list-scroll{
        min-height: calc(100vh - 310px);
        max-height: calc(100vh - 310px) ;
    } */
}

@media screen and (min-width: 769px) {
  .msg-group-info .groupSave {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .msg-messages-list.admin-mode {
    max-height: calc(100vh - 220px);
  }
  .msg-userlist-search {
    max-height: 58px;
  }
}

@media screen and (max-width: 767px) {
  /* .msg-input{
      height: 44px;
  } */
  .ReactModalPortal
    .ReactModal__Content.ReactModal__Content--after-open.newmsgMob {
    top: 50% !important;
    position: absolute;
    height: auto;
    transform: translateY(-50%);
    background: transparent !important;
    border: none !important;
    width: 80%;
    bottom: auto !important;
  }

  .msg-new.new {
    padding-top: 0;
    max-height: calc(100vh - 107px);
  }
  .ios.iphx .msg-new.new {
    padding-top: 0;
    max-height: calc(100vh - 168px);
  }
  /* .admin-user .msg-new.new{
        max-height:calc(100vh - 168px);
  } */
  .admin-user .is-admin-mob {
    display: flex;
  }

  .msg-landing.mobile {
    top: 60px;
    z-index: -1;
  }
  button#close-edit {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .msg-landing.mobile {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 888px) {
  .is-admin-header {
    padding-left: 15px;
    font-size: 14px;
    line-height: 16px;
  }
  .msg-heading.is-admin {
    padding-right: 15px;
    font-size: 16px;
  }
  .is-admin-header .pretty {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .msg-group-info {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    background: #fff;
    z-index: 9;
  }

  .outerMostContainer {
    /* overflow: hidden; */
  }
  .msg-messages-list.admin-mode {
    max-height: calc(100vh - 266px);
  }
  .msg-messages-list {
    max-height: calc(100vh - 420px);
  }
  .group-member-list {
    min-height: calc(100vh - 455px);
    max-height: calc(100vh - 455px);
  }
  .msg-lists {
    min-height: calc(100vh - 270px);
    max-height: calc(100vh - 270px);
  }
  .ios .msg-col {
    max-height: calc(100vh - 210px);
  }
  .msg-col {
    max-height: calc(100vh - 210px);
  }
  .msg-messages.group-info-open {
    max-width: calc(100% - 240px);
  }

  .msg-user-list {
    max-width: 240px;
  }
  .msg-users,
  .col-msg .msg-users {
    padding: 12px 14px;
  }
  .msg-user-pic {
    width: 32px;
    height: 32px;
  }
  .msg-messageData {
    width: calc(100% - 32px);
    padding: 0 0 0 10px;
  }
  .msg-user-pic.has-message:after {
    width: 42px;
    height: 42px;
    top: -5px;
    left: -5px;
  }
  .msg-messages {
    width: calc(100% - 240px);
  }
  .msg-form {
    padding: 25px 15px;
  }

  .msg-input button.sendBtn {
    right: 0;
  }

  button.sendBtn.emoji {
    right: 40px;
    min-width: 30px;
    max-width: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  .status-wrapper .current-status {
    width: 100% !important;
    max-width: 240px;
    padding-left: 10px !important;
  }
  .status-wrapper .unavailable {
    text-align: right;
  }
}

/* ipad pro specific */
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .ios .msg-col {
    max-height: calc(100vh - 200px);
  }
}

@media screen and (max-width: 640px) {
  .groupSave {
    padding: 0;
  }
  .groupSave button {
    max-width: 100%;
    border-radius: 0;
    padding: 10px 20px;
  }
}
@media screen and (max-width: 600px) {
  .inv-mobile-view #app .outerMostContainer {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 549px) {
  #app .outer {
    padding-top: 105px !important;
  }
  .msg-landing.mobile .landing-img {
    width: 128px;
    height: 128px;
  }

  #app .outer.chat-active .outerMostContainer {
    padding-top: 0px !important;
  }

  #app .outer.chat-active.admin-user .outerMostContainer {
    padding-top: 60px !important;
  }
  .msg-messages-list {
    max-height: calc(100vh - 126px);
  }
  .msg-messages-list.admin-mode {
    max-height: calc(100vh - 80px);
  }

  .ios .msg-messages-list {
    max-height: calc(100vh - 106px);
  }
  .ios .msg-messages-list.admin-mode {
    max-height: calc(100vh - 80px);
  }
  .ios .msg-new.new {
    max-height: calc(100vh - 117px);
  }

  .ios.iphx .msg-messages-list {
    max-height: calc(100vh - 146px);
  }
  .ios.iphx .msg-messages-list.admin-mode {
    max-height: calc(100vh - 80px);
  }

  .msg-group-info {
    max-height: calc(100vh - 105px);
  }
  .group-member-list {
    min-height: calc(100vh - 352px);
    max-height: calc(100vh - 352px);
  }

  .ios .msg-input button.sendBtn {
    right: 0px;
  }
  .ios button.sendBtn.emoji {
    right: 40px;
  }

  .msg-col.msg-messages.group-info-open {
    display: none !important;
  }

  .msg-col {
    height: 100vh;
    max-height: calc(100vh - 111px);
  }
  /* .admin-user .msg-col{
        height: 100vh;
        max-height: calc(100vh - 171px);
    } */

  .msg-lists {
    min-height: calc(100vh - 210px);
    max-height: calc(100vh - 210px);
  }

  /* .admin-user .msg-lists{
        min-height: calc(100vh - 265px);
        max-height: calc(100vh - 265px);
    } */

  /* .admin-user .msg-col.msg-messages{
        height: 100vh;
        max-height: calc(100vh - 240px);
    } */
  .group-name-form {
    min-height: calc(100vh - 183px);
  }

  .msg-col.msg-messages.is-admin {
    max-height: calc(100vh - 106px);
  }
  .msg-col.msg-messages.is-admin .msg-messages-list {
    max-height: calc(100vh - 106px);
  }
  .row.msg-message-section {
    padding-bottom: 0px;
  }

  .ios .msg-lists {
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
  }
  .ios .group-name-form {
    min-height: calc(100vh - 204px);
  }
  .admin-user .ios .msg-col {
    max-height: calc(100vh - 220px);
  }
  .admin-user .ios .msg-lists {
    min-height: calc(100vh - 325px);
    max-height: calc(100vh - 325px);
  }

  .ios .msg-modify a img {
    margin-top: 0px;
  }

  .ios .msg-userlist-search input {
    margin-right: 5px;
  }

  /*Iphone X* specific height fixes*/

  .ios.iphx .msg-lists {
    min-height: calc(100vh - 230px);
    max-height: calc(100vh - 230px);
  }
  .ios.iphx .group-name-form {
    min-height: calc(100vh - 247px);
    max-height: calc(100vh - 247px);
  }
  .admin-user .ios .msg-col.msg-user-list {
    max-height: calc(100vh - 131px);
    min-height: calc(100vh - 131px);
    overflow-x: hidden;
  }
  .admin-user .ios.iphx .msg-col {
    min-height: calc(100vh - 276px);
    max-height: calc(100vh - 276px);
  }
  .admin-user .ios.iphx .msg-col.msg-user-list {
    min-height: calc(100vh - 166px);
    max-height: calc(100vh - 166px);
    overflow-x: hidden;
  }
  .admin-user .ios .msg-lists {
    min-height: calc(100vh - 226px);
    max-height: calc(100vh - 226px);
  }
  .admin-user .ios.iphx .msg-lists {
    min-height: calc(100vh - 271px);
    max-height: calc(100vh - 271px);
  }

  /* .admin-user .ios .msg-col.msg-messages{
        height: 100vh;
        max-height: calc(100vh - 211px);
    } */
  .ios .msg-col.msg-messages {
    height: 100vh;
    max-height: calc(100vh - 241px);
  }
  .ios .msg-col.msg-messages.is-admin {
    max-height: calc(100vh - 126px);
  }
  .admin-user .ios.iphx .msg-col.msg-messages {
    height: 100vh;
    max-height: calc(100vh - 231px);
  }

  .admin-user .ios.iphx .msg-col.msg-messages.is-admin {
    max-height: calc(100vh - 166px);
  }

  .ios.iphx .msg-new .new-container .group-member-list {
    min-height: calc(100vh - 307px);
    max-height: calc(100vh - 307px);
  }
  .ios.iphx .msg-new.new .group-member-list-scroll {
    min-height: calc(100vh - 310px);
    max-height: calc(100vh - 310px);
  }
  .ios.iphx .group-member-list-scroll {
    min-height: calc(100vh - 420px);
    max-height: calc(100vh - 420px);
  }

  .ios .msg-group-info {
    max-height: calc(100vh - 180px);
  }
  .ios .group-member-list {
    min-height: calc(100vh - 372px);
    max-height: calc(100vh - 372px);
  }

  .ios.iphx .msg-group-info {
    max-height: calc(100vh - 160px);
  }
  .ios .group-member-list {
    min-height: calc(100vh - 462px);
    max-height: calc(100vh - 462px);
  }

  h3.membercount span {
    line-height: 17px;
  }
}

@media screen and (min-width: 480px) and (max-width: 501px) {
  .top_right span {
    line-height: 14px !important;
  }
}

@media screen and (max-width: 374px) {
  h3.title .msg-user-pic {
    display: none;
  }
  .ios .msg-col.msg-messages {
    height: 100vh;
    max-height: calc(100vh - 201px);
  }
}

@media screen and (max-width: 480px) {
  .name-col,
  .control-col {
    padding: 0;
  }
  .msg-messages-list {
    max-height: calc(100vh - 186px);
  }
  .msg-col.msg-messages.is-admin .msg-messages-list {
    max-height: calc(100vh - 155px);
  }

  .ios .msg-col.msg-messages.is-admin .msg-messages-list {
    max-height: calc(100vh - 125px);
  }
  #view_print_manifest input.button {
    width: 100%;
    margin: 3px 5px !important;
  }

  .deletetPopup .ReactModal__Content.ReactModal__Content--after-open {
    width: 80%;
  }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
  .msg-mob-messageheader h3.title {
    max-width: 180px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .drag-drop-toggle {
    right: 0;
  }
  .drag-drop-toggle .label-inline {
    font-size: 10px;
    padding-right: 0;
  }
}
