.checkBoxWrap {
  width: auto;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

.checkboxInput {
  width: 20px;
  height: 20px;
  margin: 0 !important;
  position: absolute;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.checkboxlabel {
  display: block;
  width: auto;
  margin: 0;
  z-index: 0;
  position: relative;
  font-family: "Proxima Nova", Georgia, sans-serif;
}

@media screen and (min-width: 1200px) {
  .checkboxlabel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.checkboxInput ~ .checkboxlabel {
  /* cursor: pointer; */
  padding: 0;
}

.checkboxInput ~ .checkboxlabel:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #9d9d9d;
}

.checkboxInput:checked ~ .checkboxlabel:before {
  background: #3f7e39;
  border-color: transparent;
}

.checkboxInput:disabled ~ .checkboxlabel {
  color: #b8b8b8;
  cursor: auto;
}

.checkboxInput:disabled ~ .checkboxlabel:before {
  box-shadow: none;
  background: #ddd;
}

.checkboxInput:checked ~ .checkboxlabel:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow:
    2px 0 0 white,
    4px 0 0 white,
    4px -2px 0 white,
    4px -4px 0 white,
    4px -6px 0 white,
    4px -8px 0 white;
  transform: rotate(45deg);
}
