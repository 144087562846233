/* 08 - oct - 2021 */

.text--lg {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.row.form-row {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #dadada;
}

.row.form-row:first-child {
  border-top: 0;
}

@media screen and (min-width: 768px) {
  .form-main-group {
    padding-left: 25px;
  }
}

button {
  padding: 5px 15px;
}

.btn-add-more {
  background-color: #3f7e39;
  color: #fff;
}

.form-group-checkbox {
  margin-top: 22px;
}

.form-group-radio,
.form-group-checkbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.form-group-radio > div,
.form-group-checkbox > div {
  padding-right: 30px;
}

.list-block {
  margin-top: 25px;
  margin-bottom: 25px;
}

.form-sub-group {
  border-top: 1px solid #dadada;
  padding-top: 25px;
  padding-bottom: 25px;
}

.form-sub-group:first-child {
  border-top: 0;
}

.form-row.row ul {
  margin-bottom: 25px;
}

.form-row.row li p {
  margin-bottom: 15px;
}

.form-row.row .list-block li p {
  margin-bottom: 5px;
}

.clearfix {
  float: none !important;
}

.officeBlock > .row {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .officeBlock label {
    font-size: 10px;
  }
}

.officeBlock > .row:before,
.officeBlock > .row:after {
  display: none;
}

.officeBlock .officeBlock__title {
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: bold;
  border-bottom: 2px dashed #d5d8d9;
  padding-bottom: 15px;
}

.officeBlock .officeBlock__title span {
  display: block;
  margin-bottom: 10px;
}

.ReactModal__Content section {
  background-color: #fefefe;
  padding: 1.5rem;
}

p.sign_close {
  color: #202020;
  font-size: 16px;
  margin: 0;
  font-weight: bold;
  font-family: "Proxima Nova", Georgia, sans-serif;
}

.ReactModal__Content .modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}

.ReactModal__Content .modal-header:before,
.ReactModal__Content .modal-header:after {
  display: none;
}

.ReactModal__Content .modal-header .close-btn span {
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.ReactModal__Content canvas {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0 1.5rem 0 0;
  max-height: 320px;
}

@media screen and (min-width: 1024px) {
  .ReactModal__Content canvas {
    max-height: 215px;
  }
}

.ReactModal__Content p.sign_buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.ReactModal__Content p.sign_buttons button {
  margin-left: 20px;
  background-color: #3f7e39;
  color: #fff;
}

.ReactModal__Content p.sign_buttons button.disabled {
  background-color: #ddd;
}

.disclaimer_checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}

.submit_button_wrapper {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.submit_button_wrapper button {
  background: #3f7e39 !important;
}

.submit_button_wrapper .no-disclaimer {
  background-color: #cfd0d2 !important;
}
