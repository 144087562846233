.container {
  min-height: 300px;
}

.content {
  padding: 20px 20px 4px;
}

.dialog_title {
  border-bottom: 1px solid #eee;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resource_order_form_drop_zone {
  position: relative;
  width: 300px;
  height: 242px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/* display: flex;
    justify-content: center;
    align-items: center; */
.crew_select_card {
  width: 54%;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

@media only screen and (max-width: 600px) {
  .crew_select_card {
    width: auto;
  }
}
