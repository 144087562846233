.print_manifest_wrap div.top_table {
  width: 100%;
  border-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  float: left;
  margin-bottom: 0;
  word-break: break-word;
}
.print_manifest_wrap .print_heading {
  box-sizing: border-box;
}
.print_manifest_wrap div.tr {
  width: 100%;
  border: 2px solid #4e4e4e;
  border-bottom: 0;
  float: left;
  border-right: 2px solid #4e4e4e;
  box-sizing: border-box;
}
.print_manifest_wrap div.td {
  float: left;
  border: 2px solid #4e4e4e;
  border-top: 0;
  border-left: 0;
  min-height: 50px;
  padding: 0 5px;
  /*min-width: 172px;*/
  border-bottom: 0;
  box-sizing: border-box;
}
.table .print_center.td.td_title {
  min-height: 24px;
  border-bottom: 0;
  width: 100%;
  box-sizing: border-box;
}
.table span.res_num {
  float: none;
}
.table .tr .td:last-child {
  border-right: 0;
}
.table .tr p {
  padding: 0;
  margin: 0;
  font-size: 13px;
}
.table .print_center.td.tr_title {
  min-height: 20px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table .td p,
.place .tr_title h5 {
  margin: 0;
}
.place .tr .double_field.td {
  width: 25%;
  min-width: auto;
  padding: 0;
  text-align: center;
}
.place .tr .double_field.td[data-header-title="Place"] {
  width: 50%;
}
.place.table .tr .double_field.td h5,
.interm_div .double_field h5 {
  margin: 0;
  border-bottom: 2px solid #4e4e4e;
  text-transform: uppercase;
  font-size: 11px;
  padding: 5px 0;
}
.place .top_table.no_border.table {
  width: 40%;
}
.place .top_table.no_border.table.interm_div {
  width: 20%;
  border-right: 2px solid #4e4e4e;
}
.interm_div .tr:first-child {
  border-top: 0 !important;
  border-left: 0;
  border-right: 0 !important;
}
.interm_div .tr {
  border-right: 0 !important;
}
.place .top_table.no_border.table .tr {
  border-left: 0 !important;
  border-right: 0;
}
.place .interm_div .tr .double_field.td {
  width: 100%;
}
.destin_div .tr:first-child {
  border-top: 0 !important;
}
.place .double_field.td h5 {
  padding: 0 5px;
}
.place .double_field span input {
  width: 90% !important;
  margin-left: 3px;
  margin-top: 6px;
}
.place .depar_div .tr .double_field.td:last-child,
.interm_div .double_field {
  border-right: 2px solid #4e4e4e;
}
#general_information_table .contrac1 .td:first-child,
#general_information_table .contrac2 .td:first-child,
#view_general_information_table .contrac1 .td:first-child,
#view_general_information_table .contrac2 .td:first-child,
#edit_general_information_table .contrac1 .td:first-child,
#edit_general_information_table .contrac2 .td:first-child {
  width: 35%;
}
#general_information_table .contrac1 .td:nth-child(2),
#general_information_table .contrac2 .td:nth-child(2),
#edit_general_information_table .contrac1 .td:nth-child(2),
#edit_general_information_table .contrac2 .td:nth-child(2) {
  width: 22%;
}
#employee_details_table .tr,
#edit_employee_details_table .tr {
  display: flex;
  flex-wrap: wrap;
}
#employee_details_table .print_center.th,
#employee_details_table .tbody .td,
#view_employee_details_table .print_center.th,
#view_employee_details_table .tbody .td {
  border: 2px solid #4e4e4e;
  flex: 1 0 15%;
  box-sizing: border-box;
  color: #171e42;
  border-left: 0;
  min-width: auto;
  min-height: auto;
  border-bottom: 0;
}
#employee_details_table .print_center.th.gender,
#employee_details_table .tbody .td.gender,
#edit_employee_details_table .print_center.th.gender,
#edit_employee_details_table .tbody .td.gender {
  flex: 1 0 2%;
  width: 50% !important;
}
#employee_details_table .print_center.th.crew_mem_no,
#employee_details_table .tbody .td.crew_mem_no,
#edit_employee_details_table .print_center.th.crew_mem_no,
#edit_employee_details_table .tbody .td.crew_mem_no {
  flex: 1 0 20%;
}
#employee_details_table .print_center.th.emt,
#employee_details_table .tbody .td.emt,
#edit_employee_details_table .print_center.th.emt,
#edit_employee_details_table .tbody .td.emt {
  flex: 1 0 4%;
}
#employee_details_table .print_center.th:last-child,
#employee_details_table .tbody .td:last-child,
#edit_employee_details_table .print_center.th:last-child,
#edit_employee_details_table .tbody .td:last-child {
  border-right: 0;
}
#employee_details_table .tbody .td {
  border-top: 0;
}
#employee_details_table .tbody .tr:last-child,
#edit_employee_details_table .tbody .tr:last-child {
  border-bottom: 2px solid #4e4e4e;
}
.interm_div .double_field[data-header-title="Place"] {
  height: 63px;
  text-align: center;
  width: 100%;
  border-right: 0;
}
#employee_details_table .print_center.th,
#edit_employee_details_table .print_center.th,
#view_employee_details_table .print_center.th {
  align-items: center;
  justify-content: center;
  display: flex;
}
#driver_vehicle_table.top_table .td h6,
#edit_driver_vehicle_table.top_table .th h6 {
  font-size: 13px;
  margin: 0;
}
#driver_vehicle_table.top_table .td,
#edit_driver_vehicle_table.top_table .th,
#edit_driver_vehicle_table.top_table .td {
  min-height: 25px;
  flex: 1 0 15%;
  align-items: center;
  display: flex;
}
#driver_vehicle_table.top_table .tbody .tr:last-child,
#edit_driver_vehicle_table.top_table .tbody .tr:last-child {
  border-bottom: 2px solid #4e4e4e;
}
#driver_vehicle_table.top_table .tr,
#edit_driver_vehicle_table.top_table .tr {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.table.sign2 .tr .td {
  min-height: 40px;
  border-bottom: 0;
}
.sign2 .tr,
.sign3 .tr,
#view_print_manifest .last_table .tr {
  display: flex;
}
.table.sign2 .tr .td:first-child {
  flex: 1 0 75%;
  line-height: 36px;
  height: 40px;
}
.place.table .tr {
  display: flex;
}
#general_information_table .tr input,
#edit_general_information_table .tr input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}
#general_information_table .tr .tdw-15 {
  min-width: 10%;
}
#general_information_table .tr,
#view_general_information_table .tr,
.ncc_view_manifest .tr {
  display: flex;
}
.place .tr_title h5 {
  font-size: 11px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  text-transform: uppercase;
}
/******************************/
#view_print_manifest input {
  padding: 3px 5px !important;
  border-radius: 0;
}
.tdw-15 {
  width: 15.3%;
}
.top_table h4 {
  font-size: 13px;
  text-transform: uppercase;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  margin-bottom: 2px;
  margin-top: 2px;
}
.print_manifest_wrap h2 {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin-bottom: 15px;
}
.td_split span {
  float: left;
  width: 88%;
}
.td_split b {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  float: left;
  width: 12%;
  font-weight: bold;
  line-height: 26px;
  font-size: 18px;
}

.print_manifest_wrap .td_split_s {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0;
}
.print_manifest_wrap .td_split_s h4 {
  width: 100%;
}

.td_split_s b {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  float: left;
  width: 12%;
  font-weight: 600;
  line-height: 26px;
  font-size: 14px;
}

.print_manifest_wrap .td_split_s span {
  display: inline-block;
  flex: 1;
}

.print_manifest_wrap .td_split_s span input {
  position: static !important;
}

.responsive_table h3 {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
  line-height: 25px;
  margin: 0;
}
.ifca_view_manifest .top_table:first-child .tr .print_center.td {
  width: 100%;
  min-height: 25px !important;
}
.print_center {
  text-align: center;
}
#view_print_manifest #view_general_information_table .td input {
  width: 98%;
  margin-bottom: 0;
  border-radius: 0;
}
.no_notification {
  font-size: 13px;
}
td.print_center.em_gender,
td.print_center.bry,
td.print_center.sawer {
  text-align: center;
  padding: 0;
  line-height: 21px;
}
td.print_center.em_gender p,
td.print_center.bry p,
td.print_center.sawer p,
#employee_details_table .pos p {
  display: block;
}
td.print_center.num {
  width: 2%;
}
#vipr_manifest .place h5 {
  font-size: 13px;
}
.ncc_edit_manifest .last_table td,
.ncc_sign td {
  vertical-align: top;
}
.ncc_edit_manifest .title_bottom,
.ncc_sign .title_bottom {
  font-size: 14px;
}
.ncc_sign .title_bottom {
  text-transform: capitalize;
}
.ncc_view_manifest .top_table td h5,
#ncc_manifest .top_table td h5 {
  font-size: 13px;
}
#vipr_manifest #employee_details_table .emp_name {
  width: 20%;
}
/*table#view_employee_details_table, .vipr_view_manifest .top_table,
table.top_table.last_table.vipr_sign {
    border: 0;
}*/
table#view_driver_vehicle_table,
#edit_driver_vehicle_table {
  border-top: 2px solid #4e4e4e;
}
#view_printing_page #general_information_table.vipr_view_manifest .top_table,
table.top_table.last_table.vipr_sign,
#view_employee_details_table.vipr_employee_details,
#vipr_manifest table.top_table {
  border-right: 0;
  border-left: 0;
}
.bul_msg_head h3 {
  display: inline-block;
}
.bulletin-msg .success_status {
  float: right;
  padding: 20px 0px;
  color: rgb(120, 3, 19);
  font-weight: bold;
  display: none;
}
#view_printing_page #general_information_table.vipr_view_manifest .top_table,
table.top_table.last_table.vipr_sign,
#view_employee_details_table.vipr_employee_details {
  border-right: 0;
  border-left: 0;
}
#employee_details_table .print_center.th:first-child,
#employee_details_table .tbody .td:first-child,
#edit_employee_details_table .print_center.th:first-child {
  flex: 1 0 20%;
}
.mobile_crew {
  display: none;
}
#amContainer .alb-info {
  height: auto;
  width: calc(100% - 60px);
  width: -webkit-calc(100% - 60px);
  width: -moz-calc(100% - 60px);
}
/**************** IFCA view manifest div - STARTS **********************/
.ifca_view_manifest .top_table:first-child .tr .print_center.td {
  width: 100%;
  min-height: 20px;
}
.ifca_employee_details .td.print_hash_value p {
  padding: 0 5px;
}
#view_printing_page .responsive_table.ifca_sign .tr {
  padding: 0 !important;
}
.ifca_view_manifest .place .top_table.no_border.table {
  width: 100%;
}
.print_manifest_wrap .place div.td {
  padding: 0;
}
.place .top_table.no_border.table .tr:first-child {
  border: 0;
}

#view_employee_details_table .tr {
  display: flex;
  flex-wrap: wrap;
}
#view_employee_details_table .print_center.th:first-child,
#view_employee_details_table .tbody .td:first-child {
  flex: 1 0 20%;
}
#view_employee_details_table .print_center.th {
  align-items: center;
  justify-content: center;
  display: flex;
}
#view_employee_details_table .print_center.th,
#view_employee_details_table .tbody .td {
  border: 2px solid #4e4e4e;
  flex: 1 0 15%;
  box-sizing: border-box;
  color: #171e42;
  border-left: 0;
  min-width: auto;
  min-height: auto;
  border-bottom: 0;
}
#view_employee_details_table .print_center.th.gender,
#view_employee_details_table .tbody .td.gender,
#view_employee_details_table .print_center.th.em_gender,
#view_employee_details_table .tbody .td.em_gender {
  flex: 1 0 2%;
  width: 50% !important;
}
#view_employee_details_table .print_center.th.emt,
#view_employee_details_table .tbody .td.emt {
  flex: 1 0 4%;
}
#view_employee_details_table .tbody .td {
  border-top: 0;
  padding: 5px 0;
}
#view_employee_details_table .print_center.th:last-child,
#view_employee_details_table .tbody .td:last-child {
  border-right: 0;
}
#view_employee_details_table .tbody .tr:last-child {
  border-bottom: 2px solid #4e4e4e;
}
#view_driver_vehicle_table.top_table .td {
  min-height: 25px;
  flex: 1 0 15%;
  align-items: center;
  display: flex;
}
#view_driver_vehicle_table.top_table .tr {
  display: flex;
  align-items: center;
  justify-content: center;
}
#view_driver_vehicle_table.top_table .td h6 {
  font-size: 13px;
  margin: 0;
}
#view_driver_vehicle_table.top_table .tbody .tr:last-child {
  border-bottom: 2px solid #4e4e4e;
}
#view_printing_page .last_table .tr {
  display: flex;
}
.table.last_table .tr .td:first-child {
  flex: 1 0 75%;
  line-height: 36px;
  min-height: 40px;
}
.table.last_table .tr .td {
  min-height: 40px;
  border-bottom: 2px solid #4e4e4e;
}
.opt-msg-select.resource {
  display: none;
}
.ifca_sign.responsive_table .top_table .tr .td {
  border-bottom: 0 !important;
}
.ifca_sign.responsive_table .top_table .tr {
  border-bottom: 2px solid #4e4e4e !important;
  min-height: auto !important;
  padding: 0px 0 10px !important;
}

.ifca_view_manifest .ifca_ordering .td {
  width: 25%;
}

#view_general_information_table.ifca_view_manifest .contrac1 .td:last-child,
#view_general_information_table.ifca_view_manifest .contrac2 .td:last-child {
  width: 38%;
}
.ifca_view_manifest .contrac1 .td:nth-child(2),
.ifca_view_manifest .contrac2 .td:nth-child(2) {
  width: 25%;
}
#view_general_information_table.ifca_view_manifest .contrac1 .td:first-child,
#view_general_information_table.ifca_view_manifest .contrac2 .td:first-child {
  width: 37%;
}
.departure_div .double_field[data-header-title="Place"],
.destin_div .double_field[data-header-title="Place"] {
  height: 63px;
}
.ifca_employee_details .Sawyer {
  flex: 1 0 10% !important;
}
#view_driver_vehicle_table.ifca_driver_vehicle_table .td {
  text-align: center;
}
#view_driver_vehicle_table.ifca_driver_vehicle_table .td h6,
#view_driver_vehicle_table.ifca_driver_vehicle_table .td p {
  width: 100%;
}
/**************** IFCA view manifest div - ENDS **********************/
/**************** NCC new table to div styles - STARTS ****************/
#ncc_manifest #general_information_table .top_table:last-child .td_height,
.ncc_edit_manifest
  #edit_general_information_table
  .top_table:last-child
  .td_height,
.ncc_view_manifest .td_height.td {
  height: 22px;
}
#ncc_manifest #general_information_table td.td_split {
  border-right: 2px solid #4e4e4e;
}
#general_information_table .top_table .tr:first-child .print_center.td,
#edit_general_information_table .top_table .tr:first-child .print_center.td {
  width: 100%;
  min-height: auto;
}
#ncc_manifest #general_information_table .td,
.ncc_edit_manifest #edit_general_information_table .td,
.ncc_view_manifest .td {
  flex: 1;
}
#ncc_manifest #general_information_table .tr input,
.ncc_view_manifest .tr input {
  margin-bottom: 5px;
  width: 100%;
}
#ncc_manifest .top_table.no_border.table:last-child h5,
.ncc_edit_manifest .top_table.no_border.table:last-child h5,
.ncc_view_manifest .top_table.no_border.table:last-child h5 {
  margin: 3px 0;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.ncc_place .no_padding_border,
#ncc_manifest #driver_vehicle_table.top_table .td {
  padding: 0 !important;
}
.ncc_place .top_table.no_border .tr {
  border-right: 0;
  border-left: 0;
}
.ncc_place .double_field.td {
  padding: 0;
}
.ncc_place .double_field h5 {
  border-bottom: 2px solid #4e4e4e;
}
.ncc_place input {
  width: 95% !important;
}
.ncc_place .outer_place_div {
  border-top: 0 !important;
}
#employee_details_table .print_center.td.num {
  flex: 1 0 2%;
  font-size: 13px;
}
#employee_details_table .print_center.em_gender.td {
  flex: 1 0 2%;
}
#ncc_manifest .td.ncc_emp_name,
.ncc_employee_details .td.ncc_emp_name {
  padding: 0 !important;
  flex: 1 0 25% !important;
}
#ncc_manifest .td.ncc_emp_name p,
.ncc_edit_manifest .td.ncc_emp_name p,
.ncc_employee_details .td.ncc_emp_name p {
  display: inline-block;
  float: left;
  padding: 7px 0;
}
#ncc_manifest .ncc_emp_name p.num,
.ncc_edit_manifest .ncc_emp_name p.num,
.ncc_employee_details .ncc_emp_name p.num {
  width: 12%;
  border-right: 2px solid #4e4e4e;
  text-align: center;
  min-height: 17px;
  height: 100%;
}
#ncc_manifest .ncc_emp_name p.ename,
.ncc_employee_details .ncc_emp_name p.ename {
  width: 80%;
  padding: 5px 5px;
}
#ncc_manifest #employee_details_table .sawyer,
.ncc_edit_manifest #edit_employee_details_table .sawyer,
#view_employee_details_table .sawyer {
  flex: 1 0 7% !important;
}
#ncc_manifest #employee_details_table .print_center.th:first-child,
#ncc_manifest #employee_details_table .print_center.td:first-child,
#ncc_manifest #employee_details_table .tbody .td:first-child,
.ncc_employee_details#view_employee_details_table .print_center.th:first-child,
.ncc_employee_details#view_employee_details_table .print_center.td:first-child {
  flex: 1 0 25%;
}
#ncc_manifest #employee_details_table .td,
.ncc_manifest #edit_employee_details_table .td {
  padding: 5px 0;
}
#ncc_manifest #employee_details_table .print_center.th.gender,
#ncc_manifest #employee_details_table .print_center.em_gender.td,
.ncc_edit_manifest #edit_employee_details_table .print_center.em_gender.td/*,
#view_employee_details_table .print_center.em_gender.td*/ {
  flex: 1 0 1%;
}
#ncc_manifest #driver_vehicle_table.top_table .th h6,
.ncc_edit_manifest #edit_driver_vehicle_table.top_table .td h6,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .th h6 {
  font-size: 13px;
  margin: 0;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
#ncc_manifest #driver_vehicle_table .th,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .th {
  border-right: 2px solid #4e4e4e;
}
#ncc_manifest #driver_vehicle_table .th:last-child,
#edit_driver_vehicle_table.top_table .th:last-child,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .th:last-child {
  border-right: 0;
}
#ncc_manifest #driver_vehicle_table.top_table .thead .tr:first-child,
.ncc_edit_manifest #edit_driver_vehicle_table.top_table .thead .tr:first-child {
  border-top: 0;
}
#ncc_manifest #driver_vehicle_table.top_table .th,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .th {
  min-height: 25px;
  flex: 1 0 15%;
  align-items: center;
  display: flex;
  justify-content: center;
}
#ncc_manifest #driver_vehicle_table.top_table .th,
#ncc_manifest #driver_vehicle_table.top_table .td,
.ncc_edit_manifest #edit_driver_vehicle_table.top_table .td,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .td {
  justify-content: center;
}
#ncc_manifest #driver_vehicle_table.top_table .st,
.ncc_edit_manifest #edit_driver_vehicle_table.top_table .st,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .st {
  flex: 1 0 2%;
}
#ncc_manifest #driver_vehicle_table.top_table .th:first-child,
#ncc_manifest #driver_vehicle_table.top_table .td:first-child,
.ncc_edit_manifest #edit_driver_vehicle_table.top_table .th:first-child,
.ncc_edit_manifest #edit_driver_vehicle_table.top_table .td:first-child,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .th:first-child,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .td:first-child {
  flex: 1 0 25%;
}
#ncc_manifest .sign .tr,
.ncc_edit_manifest .sign4 .tr {
  display: flex;
}
#ncc_manifest .sign .td,
.ncc_manifest .sign4 .td {
  text-align: center;
}
#ncc_manifest .sign .td:first-child,
.ncc_edit_manifest .sign4 .td:first-child {
  flex: 1 0 45% !important;
  line-height: normal !important;
}
#ncc_manifest .sign .td:last-child {
  flex: 1 0 25%;
}
#vipr_manifest .td,
.vipr_edit_manifest .td,
.vipr_view_manifest .td,
.vipr_sign .td {
  flex: 1;
}
#vipr_manifest .tr,
.vipr_edit_manifest .tr,
.vipr_view_manifest .tr,
.vipr_employee_details#view_employee_details_table .tr,
.vipr_sign .tr {
  border-left: 0 !important;
  border-right: 0 !important;
  display: flex;
}
#vipr_manifest .place .top_table.no_border,
.vipr_view_manifest .place .top_table.no_border {
  width: 100%;
}
#vipr_manifest .place .place1 .tr .td:first-child,
.vipr_edit_manifest .place .place1 .tr .td:first-child,
.vipr_view_manifest .place .place1 .tr .td:first-child {
  flex: 1 0 55% !important;
}
#vipr_manifest .signature .tr,
.vipr_edit_manifest .signature .tr,
.vipr_sign .tr {
  border-top: 0 !important;
  border-bottom: 2px solid #4e4e4e !important;
}
#vipr_manifest .signature .td,
.vipr_edit_manifest .signature .td,
.vipr_sign .td {
  min-height: 40px;
}
#vipr_manifest .place h5,
.vipr_edit_manifest .place h5,
.vipr_view_manifest .place h5 {
  font-size: 13px;
  margin: 0;
  min-height: 33px;
  border-bottom: 2px solid #4e4e4e;
}
#vipr_manifest #employee_details_table .print_center.td.num {
  flex: 1 0 20%;
}
#vipr_manifest #employee_details_table .print_center.th:first-child,
#vipr_manifest #employee_details_table .tbody .td:first-child,
.vipr_edit_manifest #edit_employee_details_table .print_center.th:first-child,
.vipr_edit_manifest #edit_employee_details_table .tbody .td:first-child,
.vipr_employee_details#view_employee_details_table .print_center.th:first-child,
.vipr_employee_details#view_employee_details_table .tbody .td:first-child {
  text-align: left;
  flex: 1 0 30%;
}
/*#vipr_manifest #employee_details_table .print_center.th:first-child, 
#vipr_manifest #employee_details_table .tbody .td:first-child {
    
}*/
#vipr_manifest #employee_details_table .gender,
#vipr_edit_manifest #edit_employee_details_table .gender,
.vipr_employee_details#view_employee_details_table .gender {
  flex: 1 0 0%;
  padding: 0;
}
.vipr_employee_details#view_employee_details_table .gender {
  flex: 1 0 0% !important;
}
#vipr_manifest .signature .td:first-child,
.vipr_edit_manifest .signature .td:first-child,
.vipr_sign .td:first-child {
  flex: 1 0 60%;
}
#general_information_table .place .tr input,
#edit_general_information_table .place .tr input {
  width: 97%;
  margin: 3px 0;
}
#edit_general_information_table .place .top_table.no_border.table {
  border-bottom: 2px solid #4e4e4e;
  border-right: 2px solid #4e4e4e;
}
#edit_general_information_table .place .top_table.no_border.table:last-child {
  border-right: 0;
}
#edit_general_information_table .place .top_table.no_border.table:first-child,
#edit_general_information_table .place .top_table.no_border.table:last-child {
  flex: 1 0 40%;
}
#edit_employee_details_table .print_center.th,
#edit_employee_details_table .tbody .td {
  border-right: 2px solid #4e4e4e;
  flex: 1 0 15%;
  min-height: auto;
  box-sizing: border-box;
}
/*#edit_driver_vehicle_table {
    border-top: 0;
}*/
#edit_driver_vehicle_table.top_table .th {
  border-right: 2px solid #4e4e4e;
  padding: 0 5px;
}
#edit_general_information_table .tr {
  display: flex;
}
#edit_employee_details_table .print_center.th {
  border-top: 2px solid #4e4e4e !important;
}
#edit_employee_details_table .th:first-child,
#edit_employee_details_table .td:first-child {
  flex: 1 0 25% !important;
}
.ncc_edit_manifest #edit_employee_details_table .sawyer {
  flex: 1 0 7% !important;
}
.ncc_edit_manifest #edit_employee_details_table .print_center.th.gender {
  flex: 1 0 1%;
}
.ncc_edit_manifest .ncc_emp_name p.ename {
  padding: 5px;
}
.ncc_edit_manifest #edit_employee_details_table .print_center.em_gender.td {
  padding: 5px 0;
}
.ncc_edit_manifest .td.ncc_emp_name {
  padding: 0 !important;
}
.ncc_edit_manifest #edit_employee_details_table .td {
  padding: 5px 0;
}
.ncc_edit_manifest .driver_vehicle_title {
  border-left: 0 !important;
  border-right: 0 !important;
}
.vipr_edit_manifest .place .top_table.no_border.table {
  width: 100%;
}
.vipr_edit_manifest .place h5 {
  border-bottom: 0;
}
.vipr_edit_manifest #edit_employee_details_table .print_center.th {
  border-top: 0 !important;
}
.vipr_edit_manifest #edit_employee_details_table .gender {
  flex: 1 0 0% !important;
  padding: 0 !important;
}
.ifca_edit_manifest #edit_employee_details_table .thead .tr {
  border-top: 0;
}
.vipr_employee_details#view_employee_details_table .tbody .td,
.ifca_sign .tr {
  padding: 0 !important;
}
.vipr_sign .tr {
  border-right: 2px solid #4e4e4e !important;
  border-left: 2px solid #4e4e4e !important;
}
input#resourceNumber {
  width: 100%;
  box-sizing: border-box;
}
.vipr_sign .tr {
  border-left: 0 !important;
  border-right: 0 !important;
}
.ifca_edit_manifest #edit_driver_vehicle_table .thead .tr {
  border-top: 0;
}
#print_manifest #driver_vehicle_table.top_table .td {
  min-height: 35px !important;
}
#view_print_manifest #view_general_information_table .td input {
  width: 98%;
}
#view_print_manifest
  #view_general_information_table
  .place
  .double_field
  span
  input {
  width: 95% !important;
  margin-top: 6px;
  box-sizing: border-box;
}
#view_print_manifest #view_driver_vehicle_table.top_table .td {
  min-height: 33px;
}

.view_driver_vehicle_div_ncc #view_driver_vehicle_table .th {
  text-align: center;
}

/**************** NCC new table to div styles - ENDS ****************/
.ifca_view_manifest .place .top_table.no_border.table {
  width: 100%;
}

.ifca_view_manifest
  .no_border.place
  .print_center.no_padding_border:nth-of-type(2) {
  border-right: 0;
}

#view_employee_details_table .print_center.th:first-child,
#view_employee_details_table .tbody .td:first-child {
  flex: 1 0 20%;
}
#view_employee_details_table .print_center.th,
#view_employee_details_table .tbody .td {
  border: 2px solid #4e4e4e;
  flex: 1 0 15%;
  box-sizing: border-box;
  color: #171e42;
  border-left: 0;
  min-width: auto;
  min-height: auto;
  border-bottom: 0;
}
#view_employee_details_table .print_center.th {
  align-items: center;
  justify-content: center;
  display: flex;
}
.gear-option-btn-wrap {
  text-align: center;
  padding: 10px 0;
}
.print_wrapper .gear-option-btn-wrap {
  margin-bottom: 25px;
}
#view_print_manifest input.button {
  padding: 5px 20px !important;
  margin: 0 5px;
}
#view_driver_vehicle_table.top_table .td h6 {
  font-size: 13px;
  margin: 0;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.title_bottom {
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
#view_print_manifest select {
  padding: 0px 0;
  width: 96%;
  border-radius: 0;
  font-size: 13px;
  margin: 0 auto;
  height: unset;
}
.ifca_employee_details .thead .tr {
  border-top-width: 0px !important;
}
.ifca_employee_details .tbody .tr:first-child {
  border-top-width: 0px;
}
.ifca_sign .last_table .tr,
.ncc_employee_details .tr,
.ncc_manifest_wrapper .tr.contrac2,
#view_print_manifest .last_table .tr,
#view_employee_details_table .tr {
  border-top: 0 !important;
}
.table.top_table.last_table.ncc_sign .tr .td:first-child {
  flex: 1 0 55% !important;
  line-height: normal !important;
}

.top_table.last_table.ncc_sign .td {
  min-height: 50px;
}
.responsive_table h3.inner_head_title {
  text-align: center;
  padding: 5px 0;
  float: left;
  width: 100%;
}
.place h5 {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.top_table h4 span {
  text-transform: none;
  font-size: 11px;
}
.ifca_view_manifest .print_center.top_title span {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
  line-height: 25px;
  margin: 0;
}
.ifca_view_manifest .print_center.top_title h3 {
  text-transform: capitalize;
}
#view_print_manifest .effective_date span,
.vipr_edit_manifest .effective_date span {
  font-size: 13px;
  text-transform: uppercase;
}
#view_print_manifest .effective_date,
.vipr_edit_manifest .effective_date {
  margin: 10px 0;
  text-align: center;
}
#view_print_manifest .effective_date span.mani_title,
.vipr_edit_manifest .effective_date span.mani_title {
  font-weight: bold;
  text-transform: none;
  margin-right: 15px;
}
#view_print_manifest .effective_date {
  text-align: center;
  margin-bottom: 15px;
}

.dcrm_view_manifest .top_table:first-child .tr .print_center.td {
  width: 100%;
  min-height: 25px !important;
}

/* .ifca_view_manifest .top_table:first-child .tr .print_center.td {
    width: 100%;
    min-height: 25px !important;
} */

/* DCRM Manifest*/

#finalize_manifest.dcrm .top_title {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
  line-height: 25px;
  margin: 0;
}

#finalize_manifest.dcrm form .table .tr {
  display: flex;
  flex-direction: row;
}

#finalize_manifest.dcrm form .table .th {
  flex: 1;
}

#finalize_manifest.dcrm form .table .td {
  flex: 1;
}

#finalize_manifest.dcrm form .table .th.gender,
#finalize_manifest.dcrm form .table .td.em_gender {
  flex: 0 5%;
  justify-content: center;
}

#finalize_manifest.dcrm form .table .th.crew_mem_no,
#finalize_manifest.dcrm form .table .td.em_crew_mem_no {
  flex: 0 15%;
  justify-content: center;
}

#finalize_manifest.dcrm form .table .td.em_crew_mem_no {
  flex: 0 15%;
  justify-content: center;
  letter-spacing: 2px;
}

#finalize_manifest.dcrm form .table .td.em_crew_mem_no p {
  width: 100%;
  word-break: break-all;
}

#finalize_manifest.dcrm form .table .td select,
#finalize_manifest.dcrm form .table .td input,
#finalize_manifest.dcrm form .table .td textarea {
  margin-bottom: 0px;
  max-height: 35px;
  padding: 5px;
  width: 100%;
}

#finalize_manifest.dcrm form .table .th.phone,
#finalize_manifest.dcrm form .table .td.em_phone {
  flex: 0 18%;
}

#finalize_manifest.dcrm form .middle_table .td {
  display: flex;
  align-items: center;
  min-height: 47px;
  padding: 5px;
}

#finalize_manifest.dcrm form .table .td.em_phone p {
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
}

#finalize_manifest.dcrm form .table.top_table.last_table.ncc_sign .tr {
  border-top: none;
}

#finalize_manifest.dcrm
  form
  .table.top_table.last_table.ncc_sign
  .tr
  .td:first-child {
  flex: 1 !important;
}

#finalize_manifest.dcrm
  form
  .table.top_table.last_table.ncc_sign
  .tr
  .td:last-child {
  flex: 0 25% !important;
  text-align: center;
}
#finalize_manifest.dcrm form .table.top_table.last_table.ncc_sign .tr .td.date {
  flex: 0 10% !important;
  text-align: center;
}

#finalize_manifest.dcrm .gear-option-btn-wrap input {
  margin: 10px;
}

#finalize_manifest.dcrm .duration_date_wrap {
  display: flex;
  flex-direction: row;
}

#finalize_manifest.dcrm .duration_date_wrap .manifesttDateWrapper input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #d0d3d4;
  margin-bottom: 20px;
  background: transparent;
  background: #fff;
  text-align: center;
}
#finalize_manifest.dcrm .duration_date_wrap span {
  margin: 5px 10px;
}

/* Project Manifest */

#finalize_manifest.pm .top_title {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
  line-height: 25px;
  min-height: 20px;
  margin: 0;
}

#finalize_manifest.pm form .table .tr {
  display: flex;
  flex-direction: row;
}

#finalize_manifest.pm form .table .th {
  flex: 1;
}

#finalize_manifest.pm form .table .td {
  flex: 1;
}

#finalize_manifest.pm form .table .th.gender,
#finalize_manifest.pm form .table .td.em_gender {
  flex: 0 5%;
  justify-content: center;
}

#finalize_manifest.pm form .table .th.crew_mem_no,
#finalize_manifest.pm form .table .td.em_crew_mem_no {
  flex: 0 15%;
  justify-content: center;
}

#finalize_manifest.pm form .table .td.em_crew_mem_no {
  flex: 0 15%;
  justify-content: center;
  letter-spacing: 2px;
}

#finalize_manifest.pm form .table .td.em_crew_mem_no p {
  width: 100%;
  word-break: break-all;
}

#finalize_manifest.pm form .table .td select,
#finalize_manifest.pm form .table .td input,
#finalize_manifest.pm form .table .td textarea {
  margin-bottom: 0px;
  max-height: 35px;
  padding: 5px;
  width: 100%;
}

#finalize_manifest.pm form .table .th.phone,
#finalize_manifest.pm form .table .td.em_phone {
  flex: 0 18%;
}

#finalize_manifest.pm form .middle_table .td {
  display: flex;
  align-items: center;
  min-height: 47px;
  padding: 5px;
}

#finalize_manifest.pm form .table .td.em_phone p {
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
}

#finalize_manifest.pm form .table.top_table.last_table.ncc_sign .tr {
  border-top: none;
}

#finalize_manifest.pm
  form
  .table.top_table.last_table.ncc_sign
  .tr
  .td:first-child {
  flex: 1 !important;
}

#finalize_manifest.pm
  form
  .table.top_table.last_table.ncc_sign
  .tr
  .td:last-child {
  flex: 0 25% !important;
  text-align: center;
}
#finalize_manifest.pm form .table.top_table.last_table.ncc_sign .tr .td.date {
  flex: 0 10% !important;
  text-align: center;
}

#finalize_manifest.pm .gear-option-btn-wrap input {
  margin: 10px;
}

#finalize_manifest.pm .duration_date_wrap {
  display: flex;
  flex-direction: row;
}

#finalize_manifest.pm .duration_date_wrap .manifesttDateWrapper input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #d0d3d4;
  margin-bottom: 20px;
  background: transparent;
  background: #fff;
  text-align: center;
}
#finalize_manifest.pm .duration_date_wrap span {
  margin: 5px 10px;
}

/*@media (min-width: 768px) and (max-width: 1024px) {
    .vipr_view_manifest .order_info .td h4 {
        margin-bottom: 35px;
    }
    .vipr_view_manifest .td input {
        margin-bottom: 5px;
    }
    .vipr_view_manifest .order_info .td input,
    .vipr_view_manifest .contractor_info .td input {
        margin-bottom: 5px;
        position: absolute;
        bottom: 0;
        left: 5px;
        right: 0;
        width: 96% !important;
    }
    .vipr_view_manifest .order_info .td, 
    .vipr_view_manifest .contractor_info .td{
        position: relative;
    }
}*/
/******************************************************************/
@media (max-width: 600px) {
  #finalize_manifest.pm .responsive_table .duration_date_wrap,
  #finalize_manifest.dcrm .responsive_table .duration_date_wrap {
    display: block;
  }

  #finalize_manifest.pm .react-datepicker-popper,
  #finalize_manifest.dcrm .react-datepicker-popper {
    transform: unset !important;
  }

  .table .departure.td.tr_title {
    border: 0 !important;
    margin-bottom: 0;
  }
  /*.depar_div .double_field.td{
		border-top: 1px solid #4e4e4e;
	}*/
  .responsive_table .top_table .td {
    width: 100%;
    box-sizing: border-box;
    border: 0;
    margin: 0 0 5px 0;
    min-height: auto;
  }
  .responsive_table .top_table .td h4,
  .responsive_table .top_table .td h5 {
    margin: 0 0 5px;
    padding: 0;
    text-transform: uppercase;
    text-align: left;
  }
  .responsive_table .table input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  .responsive_table .tdw-15.td,
  #driver_vehicle_table.top_table .thead,
  #edit_driver_vehicle_table.top_table .thead {
    display: none;
  }
  .responsive_table .top_table .tr {
    box-sizing: border-box;
    border: 0 !important;
    display: block !important;
    padding: 0 10px;
  }
  .place .top_table.no_border.table {
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
  }
  .place .interm_div .tr .double_field.td,
  #general_information_table .contrac1 .td:first-child {
    width: 100%;
  }
  .table .print_center.td.tr_title {
    margin-bottom: 0;
  }
  #employee_details_table p.mobile_crew,
  #edit_employee_details_table p.mobile_crew,
  .vipr_employee_details p.mobile_crew,
  .ncc_employee_details p.mobile_crew,
  .ifca_employee_details p.mobile_crew {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    min-height: 10px;
    margin-bottom: 12px !important;
    margin-top: 20px !important;
  }
  .responsive_table span.res_num h4 {
    float: left;
    margin-right: 10px;
  }
  .top_table.last_table.sign2.table {
    margin-bottom: 10px;
  }
  .responsive_table .table .td_split span {
    float: none;
    width: 100%;
  }
  .responsive_table .table p {
    padding: 0;
    float: left;
    margin: 2px 0;
  }
  .place h5.min-title {
    font-size: 13px;
    font-family: "Raleway", sans-serif;
    text-align: center !important;
  }
  .td.tr_title {
    margin: 0;
    /*border-bottom: 1px solid #4e4e4e !important;*/
    min-height: 25px !important;
  }
  .print_manifest_wrap .split_td.td:last-child {
    border-right: 0;
  }
  .print_manifest_wrap .table.td {
    border-bottom: 0;
  }
  .print_center.td.td_title {
    min-height: 24px;
    border-bottom: 0;
  }
  #employee_details_table .tr,
  #edit_employee_details_table .tr {
    display: inline-block !important;
    border-bottom: 1px dashed #4e4e4e !important;
    padding-top: 13px;
    position: relative;
    padding-bottom: 35px;
  }
  #employee_details_table
    .print_center.td[data-header-title="Incident Position"],
  #edit_employee_details_table
    .print_center.td[data-header-title="Incident Position"] {
    /* position: absolute; */
    bottom: 0px;
    width: 95%;
  }
  #employee_details_table .td,
  #edit_employee_details_table .td {
    margin-bottom: 10px;
  }
  #employee_details_table select.pos_list_drag,
  #edit_employee_details_table select.pos_list_drag,
  .ifca_employee_details .pos_list_drag.disabled,
  .ncc_employee_details .pos_list_drag.disabled {
    float: left;
    display: inline-block;
    width: calc(100% - 160px) !important;
    width: -webkit-calc(100% - 160px) !important;
    width: -moz-calc(100% - 160px) !important;
    padding: 2px;
  }
  #driver_vehicle_table.top_table .tr,
  #edit_driver_vehicle_table.top_table .tr,
  #view_driver_vehicle_table.top_table .tr {
    border-bottom: 1px dashed #4e4e4e !important;
    padding: 13px 10px 0;
  }
  #driver_vehicle_table.top_table .td,
  #edit_driver_vehicle_table.top_table .td {
    margin-bottom: 10px;
  }
  #driver_vehicle_table.top_table .tr:first-child,
  #edit_driver_vehicle_table.top_table .tr:first-child,
  #employee_details_table .middle_table.tr:first-child,
  #edit_employee_details_table .middle_table.tr:first-child,
  #view_employee_details_table .middle_table.tr:first-child,
  #view_employee_details_table .tr:first-child,
  #view_driver_vehicle_table.top_table .tr:first-child {
    border-top: 1px dashed #4e4e4e;
  }
  #print_manifest .td_split b {
    width: 10%;
  }
  #print_manifest #resourceNumber {
    width: 85%;
  }
  #employee_details_table .split_td.td,
  #edit_employee_details_table .split_td.td {
    width: 50% !important;
  }
  .vipr_sign .tr {
    border-right: 0 !important;
    border-left: 0 !important;
  }
  .vipr_sign .print_date {
    padding: 0;
  }
  /******************************************************************/
  .ncc_place .tr {
    padding: 0 5px !important;
  }
  #ncc_manifest .ncc_emp_name p.num,
  .ncc_edit_manifest .ncc_emp_name p.num,
  .ncc_employee_details .ncc_emp_name p.num {
    display: none;
  }
  #ncc_manifest .ncc_emp_name p.ename,
  .ncc_edit_manifest .ncc_emp_name p.ename {
    margin: -2px 0;
  }
  #ncc_manifest #employee_details_table .td,
  .ncc_edit_manifest #edit_employee_details_table .td {
    margin-bottom: 5px;
  }
  #ncc_manifest
    #employee_details_table
    .print_center.td[data-header-title="Incident Position"] {
    bottom: 10px;
  }
  #ncc_manifest #employee_details_table .tr {
    padding-bottom: 45px;
  }
  #ncc_manifest #employee_details_table .print_center.th.gender,
  #ncc_manifest #employee_details_table .print_center.em_gender.td {
    width: 50%;
  }
  #ncc_manifest .responsive_table #driver_vehicle_table,
  .ncc_edit_manifest .responsive_table #edit_driver_vehicle_table {
    border-top: 0;
  }
  #ncc_manifest #driver_vehicle_table.top_table .th,
  #ncc_manifest #driver_vehicle_table.top_table .td,
  .ncc_edit_manifest #edit_driver_vehicle_table.top_table .td,
  #ncc_manifest #driver_vehicle_table.top_table .td,
  .view_driver_vehicle_div_ncc #view_driver_vehicle_table .td {
    justify-content: left;
  }
  #ncc_manifest .sign .td:last-child,
  .ncc_edit_manifest .sign4 .td:last-child {
    border-right: 0;
  }
  #ncc_manifest .sign .td,
  .ncc_edit_manifest .sign4 .td {
    width: 50%;
    border-right: 2px solid #4e4e4e;
  }
  #ncc_manifest .sign .td:first-child,
  .ncc_edit_manifest .sign4 .td:first-child {
    width: 100%;
    border-right: 0;
    margin: 0;
  }
  #ncc_manifest .sign .tr,
  .ncc_edit_manifest .sign4 .tr {
    padding: 0;
  }
  #ncc_manifest .sign .print_date {
    padding: 0;
  }
  #vipr_manifest .place,
  .vipr_edit_manifest .place {
    padding: 0 15px;
    box-sizing: border-box;
  }
  #vipr_manifest
    #general_information_table
    .top_table
    .tr:first-child
    .print_center.td,
  .vipr_edit_manifest
    #edit_general_information_table
    .top_table
    .tr:first-child
    .print_center.td {
    width: 100% !important;
  }
  #vipr_manifest #employee_details_table .number,
  .vipr_edit_manifest #edit_employee_details_table .number {
    display: none;
  }
  #vipr_manifest #employee_details_table .gender,
  .vipr_edit_manifest #edit_employee_details_table .gender {
    padding: 0 5px !important;
  }
  #vipr_manifest .pos.td p,
  .vipr_edit_manifest .pos.td p {
    width: calc(100% - 100px);
    width: -webkit-calc(100% - 24%);
    width: -moz-calc(100% - 24%);
    float: left;
    margin: -2px 0 0px 5px;
  }
  #vipr_manifest select.pos_list_drag,
  .vipr_edit_manifest select.pos_list_drag {
    width: 100%;
  }
  #vipr_manifest .signature,
  .vipr_edit_manifest .signature {
    border-top: 2px solid #4e4e4e;
  }
  #vipr_manifest .signature .td:first-child,
  .vipr_edit_manifest .signature .td:first-child {
    width: 70% !important;
  }
  #vipr_manifest .signature .td:last-child,
  .vipr_edit_manifest .signature .td:last-child {
    width: 27%;
    margin: 0;
  }
  #vipr_manifest #employee_details_table .tr,
  .vipr_edit_manifest #edit_employee_details_table .tr {
    padding-bottom: 0;
  }
  #vipr_manifest #employee_details_table .tr:last-child,
  .vipr_edit_manifest #edit_employee_details_table .tr:last-child,
  .vipr_employee_details#view_employee_details_table .tr:last-child {
    border-bottom: 0 !important;
  }
  .vipr_edit_manifest .signature {
    margin-bottom: 15px;
  }
  #vipr_manifest .signature h4,
  .vipr_edit_manifest .signature h4 {
    display: block !important;
    padding-top: 5px !important;
  }
  #vipr_manifest .signature .td span.print_date,
  .vipr_edit_manifest .signature .td span.print_date {
    padding: 0;
  }
  #vipr_manifest .signature .td:first-child,
  .vipr_edit_manifest .signature .td:first-child {
    margin: 0;
  }
  #vipr_manifest h3.inner_head_title.note {
    padding: 0 15px;
  }
  #vipr_manifest select.perssonal,
  .vipr_edit_manifest select.perssonal {
    width: 100% !important;
    padding: 5px 5px !important;
  }
  #edit_general_information_table .contrac1 .td:nth-child(2),
  #edit_general_information_table .contrac2 .td:nth-child(2) {
    width: 100%;
  }
  #edit_general_information_table
    .td[data-header-title="Contractor Represntative"],
  .vipr_view_manifest#general_information_table .top_table .tr:first-child .td {
    width: 100% !important;
  }
  #edit_general_information_table .place .top_table.no_border.table {
    border-bottom: 0;
    border-right: 0;
  }
  .responsive_table .top_table.sign3 .tr {
    padding: 0;
    border-bottom: 2px solid #4e4e4e;
  }
  .responsive_table
    #edit_employee_details_table.top_table
    .last_night.td[data-header-title]:before {
    width: 75%;
  }
  .vipr_employee_details .thead,
  #view_driver_vehicle_table .thead {
    display: none;
  }
  #view_employee_details_table .tbody .td {
    border-right: 0;
  }
  #view_employee_details_table .tr {
    border-top: 1px dashed #4e4e4e !important;
  }
  .vipr_employee_details .tr,
  .ncc_employee_details .tr {
    padding-top: 13px !important;
    border: 0 !important;
  }
  #view_employee_details_table .tbody .tr:last-child {
    border-bottom: 1px dashed #4e4e4e !important;
  }
  .view_driver_vehicle_div_ncc #view_driver_vehicle_table .td {
    justify-content: left;
  }
  .ifca_sign.responsive_table .top_table .tr {
    display: flex !important;
  }
  .ifca_sign.responsive_table .top_table .tr .td {
    line-height: normal !important;
    padding: 5px 10px !important;
    min-height: 90px;
  }
  .ifca_view_manifest#view_general_information_table .td {
    width: 100% !important;
    margin-bottom: 5px !important;
  }
  .ifca_sign .print_date {
    padding: 0;
  }
  .ifca_employee_details .tr {
    padding-top: 7px !important;
  }
  .ifca_employee_details .pos_list_drag.disabled {
    float: left;
    margin-left: 5px;
  }
  #view_employee_details_table .tr {
    padding-bottom: 35px !important;
    position: relative;
  }
  #view_employee_details_table
    .print_center.td[data-header-title="Incident Position"] {
    opacity: 0.5;
    /* position: absolute; */
    bottom: 0px;
    width: 95%;
  }

  #edit_driver_vehicle_table {
    border-top: 0 !important ;
  }
  .ifca_sign.responsive_table .top_table .tr .td:last-child {
    padding-left: 0 !important;
  }
  .ifca_view_manifest#view_general_information_table .td.print_center {
    margin-bottom: 25px;
  }
  #view_print_manifest #view_general_information_table .td input {
    width: 100%;
  }
  .place.table .tr .double_field.td h5,
  .interm_div .double_field h5 {
    border-bottom: 0;
  }
  #view_print_manifest
    #view_general_information_table
    .place
    .double_field
    span
    input {
    width: 100% !important;
    margin: 0;
  }
  .place.table .tr .double_field.td h5,
  .interm_div .double_field h5 {
    margin: 0 0 5px;
  }
  .place .top_table.no_border.table.interm_div {
    border-right: 0;
  }
  #view_general_information_table .place .tr .td.tr_title {
    margin-bottom: 0px !important;
  }
  .responsive_table .table p.mobile_crew {
    font-family: "proxima_nova_altbold", Georgia, sans-serif;
  }
  #driver_vehicle_table.top_table .tr:first-child,
  #edit_driver_vehicle_table.top_table .tr:first-child,
  #employee_details_table .middle_table.tr:first-child,
  #edit_employee_details_table .middle_table.tr:first-child,
  #view_employee_details_table .middle_table.tr:first-child,
  #view_employee_details_table .tr:first-child,
  #view_driver_vehicle_table.top_table .tr:first-child {
    border-top: 1px dashed #4e4e4e !important;
  }
  #employee_details_table .thead,
  #edit_employee_details_table .thead,
  .ncc_employee_details .thead,
  .ifca_employee_details .thead {
    display: none;
  }
  #view_employee_details_table .tbody .td {
    border-top: 0;
    padding: 5px 0;
  }
  .responsive_table .top_table .td[data-header-title]:before,
  .ncc_employee_details .td[data-header-title]:before {
    content: attr(data-header-title) ":";
    display: block;
    color: #000;
    float: left;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    margin-bottom: 2px;
    margin-top: 2px;
    margin-right: 5px;
    width: 99%;
  }
  .ncc_employee_details.top_table .tr .td[data-header-title]:before {
    text-align: left;
    width: auto;
  }
  .ncc_employee_details.top_table .tr {
    box-sizing: border-box;
    border: 0;
    display: block !important;
    padding: 0 15px;
  }
  .ncc_employee_details .td {
    width: 100%;
  }
  .responsive_table
    #employee_details_table.top_table
    .td[data-header-title]:before,
  .responsive_table
    #edit_employee_details_table.top_table
    .td[data-header-title]:before,
  .responsive_table
    #view_employee_details_table.top_table
    .td[data-header-title]:before {
    text-align: left;
    width: auto;
  }
  .responsive_table .top_table h4,
  .responsive_table .top_table h5 {
    display: none;
  }
  .responsive_table .top_table .td.double_field[data-header-title]:before {
    font-size: 11px;
    padding-left: 0px;
    text-align: left;
    box-sizing: border-box;
  }
  .view_manifest_page.content_div {
    padding: 0;
  }
  .responsive_table .top_table .split_td.td[data-header-title]:before,
  .responsive_table
    #driver_vehicle_table.top_table
    .td[data-header-title]:before,
  .responsive_table
    #edit_driver_vehicle_table.top_table
    .td[data-header-title]:before,
  .responsive_table
    #view_driver_vehicle_table.top_table
    .td[data-header-title]:before {
    width: auto;
  }
  #view_employee_details_table.ifca_employee_details .tr {
    padding-bottom: 50px !important;
  }
  .ncc_place input,
  .vipr_manifest_wrapper #general_information_table .place .tr input {
    width: 100% !important;
  }
  .ncc_employee_details .ncc_emp_name p.ename {
    padding: 0;
    margin: 0;
  }
  .table.top_table.last_table.ncc_sign .tr {
    display: flex !important;
    padding: 0;
    border-right: 0;
    border-left: 0;
  }
  .top_table.last_table.ncc_sign .td {
    min-height: 70px;
    border-top: 2px solid #4e4e4e;
  }
  #view_driver_vehicle_table .tbody .tr:last-child {
    border-bottom: 0 !important;
  }
  .vipr_manifest_wrapper .place .tr .td[data-header-title]:before {
    text-align: left;
  }
  .vipr_view_manifest select.perssonal {
    width: 60px !important;
  }

  #finalize_manifest.pm
    .duration_date_wrap
    #finalize_manifest.dcrm
    .duration_date_wrap {
    display: block;
  }

  #finalize_manifest.pm .duration_date_wrap .manifesttDateWrapper > div,
  #finalize_manifest.dcrm .duration_date_wrap .manifesttDateWrapper > div {
    position: relative;
  }
}

.finalize_manifest .breadcrumbs {
  margin-bottom: 25px;
}
.vehicle_manifest .breadcrumbs {
  margin-bottom: 15px;
}

.view_driver_vehicle_div_ncc #view_driver_vehicle_table .th,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .td {
  padding: 0 !important;
}

.view_driver_vehicle_div_ncc #view_driver_vehicle_table .th.st,
.view_driver_vehicle_div_ncc #view_driver_vehicle_table .td.st {
  flex: 1 0 5% !important;
}
#general_information_table.ncc_view_manifest h4,
#general_information_table.ncc_view_manifest .tr p {
  text-align: center;
}
.ncc_manifest_wrapper .ncc_sign .td {
  text-align: center;
}

.ncc_dep .double_field.td:first-child,
.ncc_inter .double_field.td:last-child {
  flex: 1 0 45%;
}
.ncc_des .double_field.td:last-child {
  flex: 1 0 35%;
}

.ncc_des .double_field.td:first-child {
  flex: 1 0 35%;
}

.flex-des {
  flex: 1 1 !important;
}

.manifesttDateWrapper {
  display: inline-block;
  /* margin-right: 10px; */
}

.manifesttDateWrapper input {
  border: none;
  border-bottom: solid 1px #333;
  width: 106px;
  display: inline-block;
  max-width: 250px;
  outline: none !important ;
}

#view_print_manifest .effective_date span,
.vipr_edit_manifest .effective_date span {
  display: inline-block;
  margin: 0 10px;
  word-break: break-word;
}

.td[data-header-title="DL Issued State"] {
  text-transform: uppercase;
}

.manifesttDateWrapper input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-transform: uppercase;
}
.manifesttDateWrapper input::-moz-placeholder {
  /* Firefox 19+ */
  text-transform: uppercase;
}
.manifesttDateWrapper input:-ms-input-placeholder {
  /* IE 10+ */
  text-transform: uppercase;
}
.manifesttDateWrapper input:-moz-placeholder {
  /* Firefox 18- */
  text-transform: uppercase;
}

.lastNightWrapper input {
  margin-bottom: 0;
  border: none !important ;
  outline: none;
}
.lastNightWrapper .react-datepicker-wrapper + .react-datepicker-popper {
  top: 0 !important ;
}

.end-date-error .manifesttDateWrapper input {
  background: #ffdcdc !important;
  border-bottom-color: #ff0000 !important;
}

.fs-toast {
  position: fixed;
  background: #252525;
  width: 250px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  color: #fff;
  padding: 15px;
  border-radius: 3px;
  user-select: none;
}
.fs-toast.fs-visible {
  display: block;
}
.manifesttDateWrapper .react-datepicker__triangle {
  display: none;
}

.btn-disabled {
  background-color: #747474 !important ;
}

.ReactModal__Content.ReactModal__Content--after-open {
  max-width: 600px;
}

.resource_order_form_actions_container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.resource_order_form_actions_container button {
  padding: 5px 20px !important;
}

.resource_order_form_delete_modal_action {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  gap: 10px;
}

.resource_order_form_delete_modal_action button:first-child {
  margin-right: 5px;
}

.ro_upload_btn {
  margin: 0 5px;
}

.print_manifest_wrap {
  width: 100%;
  overflow-x: auto;
}
@media screen and (min-width: 680px) {
  .print_manifest_wrap > form {
    min-width: 1200px;
  }
}

.print_manifest_wrap .gear-option-btn-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.print_manifest_wrap .gear-option-btn-wrap input {
  margin: 0;
}

@media screen and (max-width: 767px) {
  #view_printing_page .last_table .tr,
  #view_print_manifest .last_table .tr {
    display: block !important;
    border-top: 1px dashed #4e4e4e !important;
  }
  #view_printing_page .last_table .tr .td,
  #view_print_manifest .last_table .tr .td {
    width: 100%;
    min-height: unset;
    padding: 12px 8px;
    border: none;
    border-bottom: 1px dashed #4e4e4e !important;
  }
  #view_printing_page .last_table .tr .td span,
  #view_print_manifest .last_table .tr .td span {
    display: inline-block;
    margin-bottom: 12px;
  }
}
