.container {
  background-color: #fff;
}

.dialog_title {
  border-bottom: 1px solid #eee;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f9fa;
}

.content {
  padding: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 698px) {
  .buttonContainer {
    flex-direction: column;
    gap: 8px;
  }
}
@media screen and (max-width: 498px) {
  .registerBtn {
    font-size: 10px;
    height: 32px;
  }
}

.marginRight {
  margin-right: 5px;
}

.minHeight {
  min-height: 72px;
}

.h4Style {
  /* font-family: 'Proxima Nova'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
