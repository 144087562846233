@import url("https://use.typekit.net/jdg6ipt.css");

/*firestorm  messaging 
 	css prefix: msg-
 */

/* custom checkbox */

.pretty * {
  box-sizing: border-box;
}

.pretty input:not([type="checkbox"]):not([type="radio"]) {
  display: none;
}

.pretty {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1;
}

.pretty input {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 1em;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.pretty .state label {
  position: initial;
  display: inline-block;
  font-weight: 400;
  margin: 0;
  text-indent: 1.5em;
  min-width: calc(1em + 2px);
}

.pretty .state label:after,
.pretty .state label:before {
  content: "";
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  display: block;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid transparent;
  z-index: 0;
  position: absolute;
  left: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  background-color: transparent;
}

.pretty .state label:before {
  border-color: #bdc3c7;
}

.pretty .state.p-is-hover,
.pretty .state.p-is-indeterminate {
  display: none;
}

@-webkit-keyframes zoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes zoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes tada {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: scale(7);
    transform: scale(7);
  }
  38% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  55% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  72% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  81% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: scale(1.24);
    transform: scale(1.24);
  }
  89% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  95% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: scale(1.04);
    transform: scale(1.04);
  }
  100% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes tada {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: scale(7);
    transform: scale(7);
  }
  38% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  55% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  72% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  81% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: scale(1.24);
    transform: scale(1.24);
  }
  89% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  95% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: scale(1.04);
    transform: scale(1.04);
  }
  100% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes jelly {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jelly {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes rotate {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-200px) rotate(-45deg);
    transform: translateZ(-200px) rotate(-45deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) rotate(0);
    transform: translateZ(0) rotate(0);
  }
}

@keyframes rotate {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-200px) rotate(-45deg);
    transform: translateZ(-200px) rotate(-45deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) rotate(0);
    transform: translateZ(0) rotate(0);
  }
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #bdc3c7;
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(189, 195, 199, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #bdc3c7;
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(189, 195, 199, 0);
  }
}

.pretty.p-default.p-fill .state label:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.pretty.p-default .state label:after {
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
}

.pretty.p-default input:checked ~ .state label:after {
  background-color: #bdc3c7 !important;
}

.pretty.p-default.p-thick .state label:after,
.pretty.p-default.p-thick .state label:before {
  border-width: calc(1em / 7);
}

.pretty.p-default.p-thick .state label:after {
  -webkit-transform: scale(0.4) !important;
  -ms-transform: scale(0.4) !important;
  transform: scale(0.4) !important;
}

.pretty.p-icon .state .icon {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0;
}

.pretty.p-icon .state .icon:before {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.pretty.p-icon input:checked ~ .state .icon {
  opacity: 1;
}

.pretty.p-icon input:checked ~ .state label:before {
  border-color: #5a656b;
}

.pretty.p-svg .state .svg {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0;
}

.pretty.p-svg .state svg {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.pretty.p-svg input:checked ~ .state .svg {
  opacity: 1;
}

.pretty.p-image .state img {
  opacity: 0;
  position: absolute;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  top: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  left: 0;
  z-index: 0;
  text-align: center;
  line-height: normal;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.pretty.p-image input:checked ~ .state img {
  opacity: 1;
}

.pretty.p-switch input {
  min-width: 2em;
}

.pretty.p-switch .state {
  position: relative;
}

.pretty.p-switch .state:before {
  content: "";
  border: 1px solid #bdc3c7;
  border-radius: 60px;
  width: 2em;
  box-sizing: unset;
  height: calc(1em + 2px);
  position: absolute;
  top: 0;
  top: calc((0% - (100% - 1em)) - 16%);
  z-index: 0;
  transition: all 0.5s ease;
}

.pretty.p-switch .state label {
  text-indent: 2.5em;
}

.pretty.p-switch .state label:after,
.pretty.p-switch .state label:before {
  transition: all 0.5s ease;
  border-radius: 100%;
  left: 0;
  border-color: transparent;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.pretty.p-switch .state label:after {
  background-color: #bdc3c7 !important;
}

.pretty.p-switch input:checked ~ .state:before {
  border-color: #5a656b;
}

.pretty.p-switch input:checked ~ .state label:before {
  opacity: 0;
}

.pretty.p-switch input:checked ~ .state label:after {
  background-color: #5a656b !important;
  left: 1em;
}

.pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important;
}

.pretty.p-switch.p-fill input:checked ~ .state label:before {
  opacity: 0;
}

.pretty.p-switch.p-fill input:checked ~ .state label:after {
  background-color: #fff !important;
  left: 1em;
}

.pretty.p-switch.p-slim .state:before {
  height: 0.1em;
  background: #bdc3c7 !important;
  top: calc(50% - 0.1em);
}

.pretty.p-switch.p-slim input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important;
}

.pretty.p-has-hover input:hover ~ .state:not(.p-is-hover) {
  display: none;
}

.pretty.p-has-hover input:hover ~ .state.p-is-hover {
  display: block;
}

.pretty.p-has-hover input:hover ~ .state.p-is-hover .icon {
  display: block;
}

.pretty.p-has-focus input:focus ~ .state label:before {
  box-shadow: 0 0 3px 0 #bdc3c7;
}

.pretty.p-has-indeterminate
  input[type="checkbox"]:indeterminate
  ~ .state:not(.p-is-indeterminate) {
  display: none;
}

.pretty.p-has-indeterminate
  input[type="checkbox"]:indeterminate
  ~ .state.p-is-indeterminate {
  display: block;
}

.pretty.p-has-indeterminate
  input[type="checkbox"]:indeterminate
  ~ .state.p-is-indeterminate
  .icon {
  display: block;
  opacity: 1;
}

.pretty.p-toggle .state.p-on {
  opacity: 0;
  display: none;
}

.pretty.p-toggle .state .icon,
.pretty.p-toggle .state .svg,
.pretty.p-toggle .state img,
.pretty.p-toggle .state.p-off {
  opacity: 1;
  display: inherit;
}

.pretty.p-toggle .state.p-off .icon {
  color: #bdc3c7;
}

.pretty.p-toggle input:checked ~ .state.p-on {
  opacity: 1;
  display: inherit;
}

.pretty.p-toggle input:checked ~ .state.p-off {
  opacity: 0;
  display: none;
}

.pretty.p-plain input:checked ~ .state label:before,
.pretty.p-plain.p-toggle .state label:before {
  content: none;
}

.pretty.p-plain.p-plain .icon {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.pretty.p-round .state label:after,
.pretty.p-round .state label:before {
  border-radius: 100%;
}

.pretty.p-round.p-icon .state .icon {
  border-radius: 100%;
  overflow: hidden;
}

.pretty.p-round.p-icon .state .icon:before {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.pretty.p-curve .state label:after,
.pretty.p-curve .state label:before {
  border-radius: 20%;
}

.pretty.p-smooth .icon,
.pretty.p-smooth .svg,
.pretty.p-smooth label:after,
.pretty.p-smooth label:before {
  transition: all 0.5s ease;
}

.pretty.p-smooth input:checked + .state label:after {
  transition: all 0.3s ease;
}

.pretty.p-smooth input:checked + .state .icon,
.pretty.p-smooth input:checked + .state .svg,
.pretty.p-smooth input:checked + .state img {
  -webkit-animation: zoom 0.2s ease;
  animation: zoom 0.2s ease;
}

.pretty.p-smooth.p-default input:checked + .state label:after {
  -webkit-animation: zoom 0.2s ease;
  animation: zoom 0.2s ease;
}

.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: "";
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  transition: all 0.5s ease;
}

.pretty.p-tada:not(.p-default) input:checked + .state .icon,
.pretty.p-tada:not(.p-default) input:checked + .state .svg,
.pretty.p-tada:not(.p-default) input:checked + .state img,
.pretty.p-tada:not(.p-default) input:checked + .state label:after,
.pretty.p-tada:not(.p-default) input:checked + .state label:before {
  -webkit-animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
  animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
  opacity: 1;
}

.pretty.p-jelly:not(.p-default) input:checked + .state .icon,
.pretty.p-jelly:not(.p-default) input:checked + .state .svg,
.pretty.p-jelly:not(.p-default) input:checked + .state img,
.pretty.p-jelly:not(.p-default) input:checked + .state label:after,
.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  -webkit-animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}

.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  border-color: transparent;
}

.pretty.p-rotate:not(.p-default) input:checked ~ .state .icon,
.pretty.p-rotate:not(.p-default) input:checked ~ .state .svg,
.pretty.p-rotate:not(.p-default) input:checked ~ .state img,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:after,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  -webkit-animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}

.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  border-color: transparent;
}

.pretty.p-pulse:not(.p-switch) input:checked ~ .state label:before {
  -webkit-animation: pulse 1s;
  animation: pulse 1s;
}

.pretty input[disabled] {
  cursor: not-allowed;
  display: none;
}

.pretty input[disabled] ~ * {
  opacity: 0.5;
}

.pretty.p-locked input {
  display: none;
  cursor: not-allowed;
}

.pretty input:checked ~ .state.p-primary label:after,
.pretty.p-toggle .state.p-primary label:after {
  background-color: #428bca !important;
}

.pretty input:checked ~ .state.p-primary .icon,
.pretty input:checked ~ .state.p-primary .svg,
.pretty.p-toggle .state.p-primary .icon,
.pretty.p-toggle .state.p-primary .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-primary-o label:before,
.pretty.p-toggle .state.p-primary-o label:before {
  border-color: #428bca;
}

.pretty input:checked ~ .state.p-primary-o label:after,
.pretty.p-toggle .state.p-primary-o label:after {
  background-color: transparent;
}

.pretty input:checked ~ .state.p-primary-o .icon,
.pretty input:checked ~ .state.p-primary-o .svg,
.pretty input:checked ~ .state.p-primary-o svg,
.pretty.p-toggle .state.p-primary-o .icon,
.pretty.p-toggle .state.p-primary-o .svg,
.pretty.p-toggle .state.p-primary-o svg {
  color: #428bca;
  stroke: #428bca;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #428bca !important;
}

.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #428bca;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #428bca !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-primary:before {
  border-color: #245682;
  background-color: #245682 !important;
}

.pretty input:checked ~ .state.p-info label:after,
.pretty.p-toggle .state.p-info label:after {
  background-color: #5bc0de !important;
}

.pretty input:checked ~ .state.p-info .icon,
.pretty input:checked ~ .state.p-info .svg,
.pretty.p-toggle .state.p-info .icon,
.pretty.p-toggle .state.p-info .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-info-o label:before,
.pretty.p-toggle .state.p-info-o label:before {
  border-color: #5bc0de;
}

.pretty input:checked ~ .state.p-info-o label:after,
.pretty.p-toggle .state.p-info-o label:after {
  background-color: transparent;
}

.pretty input:checked ~ .state.p-info-o .icon,
.pretty input:checked ~ .state.p-info-o .svg,
.pretty input:checked ~ .state.p-info-o svg,
.pretty.p-toggle .state.p-info-o .icon,
.pretty.p-toggle .state.p-info-o .svg,
.pretty.p-toggle .state.p-info-o svg {
  color: #5bc0de;
  stroke: #5bc0de;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #5bc0de !important;
}

.pretty.p-switch input:checked ~ .state.p-info:before {
  border-color: #5bc0de;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-info:before {
  background-color: #5bc0de !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-info:before {
  border-color: #2390b0;
  background-color: #2390b0 !important;
}

.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: #5cb85c !important;
}

.pretty input:checked ~ .state.p-success .icon,
.pretty input:checked ~ .state.p-success .svg,
.pretty.p-toggle .state.p-success .icon,
.pretty.p-toggle .state.p-success .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-success-o label:before,
.pretty.p-toggle .state.p-success-o label:before {
  border-color: #5cb85c;
}

.pretty input:checked ~ .state.p-success-o label:after,
.pretty.p-toggle .state.p-success-o label:after {
  background-color: transparent;
}

.pretty input:checked ~ .state.p-success-o .icon,
.pretty input:checked ~ .state.p-success-o .svg,
.pretty input:checked ~ .state.p-success-o svg,
.pretty.p-toggle .state.p-success-o .icon,
.pretty.p-toggle .state.p-success-o .svg,
.pretty.p-toggle .state.p-success-o svg {
  color: #5cb85c;
  stroke: #5cb85c;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #5cb85c !important;
}

.pretty.p-switch input:checked ~ .state.p-success:before {
  border-color: #5cb85c;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
  background-color: #5cb85c !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-success:before {
  border-color: #357935;
  background-color: #357935 !important;
}

.pretty input:checked ~ .state.p-warning label:after,
.pretty.p-toggle .state.p-warning label:after {
  background-color: #f0ad4e !important;
}

.pretty input:checked ~ .state.p-warning .icon,
.pretty input:checked ~ .state.p-warning .svg,
.pretty.p-toggle .state.p-warning .icon,
.pretty.p-toggle .state.p-warning .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-warning-o label:before,
.pretty.p-toggle .state.p-warning-o label:before {
  border-color: #f0ad4e;
}

.pretty input:checked ~ .state.p-warning-o label:after,
.pretty.p-toggle .state.p-warning-o label:after {
  background-color: transparent;
}

.pretty input:checked ~ .state.p-warning-o .icon,
.pretty input:checked ~ .state.p-warning-o .svg,
.pretty input:checked ~ .state.p-warning-o svg,
.pretty.p-toggle .state.p-warning-o .icon,
.pretty.p-toggle .state.p-warning-o .svg,
.pretty.p-toggle .state.p-warning-o svg {
  color: #f0ad4e;
  stroke: #f0ad4e;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #f0ad4e !important;
}

.pretty.p-switch input:checked ~ .state.p-warning:before {
  border-color: #f0ad4e;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-warning:before {
  background-color: #f0ad4e !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-warning:before {
  border-color: #c77c11;
  background-color: #c77c11 !important;
}

.pretty input:checked ~ .state.p-danger label:after,
.pretty.p-toggle .state.p-danger label:after {
  background-color: #d9534f !important;
}

.pretty input:checked ~ .state.p-danger .icon,
.pretty input:checked ~ .state.p-danger .svg,
.pretty.p-toggle .state.p-danger .icon,
.pretty.p-toggle .state.p-danger .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-danger-o label:before,
.pretty.p-toggle .state.p-danger-o label:before {
  border-color: #d9534f;
}

.pretty input:checked ~ .state.p-danger-o label:after,
.pretty.p-toggle .state.p-danger-o label:after {
  background-color: transparent;
}

.pretty input:checked ~ .state.p-danger-o .icon,
.pretty input:checked ~ .state.p-danger-o .svg,
.pretty input:checked ~ .state.p-danger-o svg,
.pretty.p-toggle .state.p-danger-o .icon,
.pretty.p-toggle .state.p-danger-o .svg,
.pretty.p-toggle .state.p-danger-o svg {
  color: #d9534f;
  stroke: #d9534f;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #d9534f !important;
}

.pretty.p-switch input:checked ~ .state.p-danger:before {
  border-color: #d9534f;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-danger:before {
  background-color: #d9534f !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-danger:before {
  border-color: #a02622;
  background-color: #a02622 !important;
}

.pretty.p-bigger .icon,
.pretty.p-bigger .img,
.pretty.p-bigger .svg,
.pretty.p-bigger label:after,
.pretty.p-bigger label:before {
  font-size: 1.2em !important;
  top: calc((0% - (100% - 1em)) - 35%) !important;
}

.pretty.p-bigger label {
  text-indent: 1.7em;
}

@media print {
  .pretty .state .icon,
  .pretty .state label:after,
  .pretty .state label:before,
  .pretty .state:before {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/* custom checkbox ends*/

body,
html {
  font-family: "Proxima Nova", sans-serif;
  /* overflow-x: hidden; */
}

body {
  background: #eef0f2;
}

.chat-active h1,
.chat-active h2,
.chat-active h3,
.chat-active h4,
.chat-active h5,
.chat-active h6 {
  font-family: "Proxima Nova", sans-serif !important;
}

.msg {
  font-size: 14px;
  font-family: "Proxima Nova", sans-serif !important;
  background: #fff;
}

.msg-hide {
  display: block;
}

.row.msg {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
}
.row.msg .col-sm-4,
.row.msg .col-sm-8 {
  display: flex;
  align-items: center;
}
.row.msg .col-sm-4 {
  width: 100%;
}
.row.msg .col-sm-8 {
  width: 100%;
  flex-direction: row-reverse;
}

.row.added-members {
  max-height: 100px;
  overflow: hidden;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 50px;
}

.row.added-members.all {
  max-height: max-content;
  overflow: auto;
}

.row.added-members .col-md-12 {
  padding: 0;
}

.msg-link {
  color: #407f38;
}

.msg-col {
  display: block;
  float: left;
  position: relative;
  max-height: calc(100vh - 145px);
}

.msg-userlist-search {
  width: 100%;
  min-height: 58px;
  border-bottom: 1px solid #d8d8d8;
  padding-left: 25px;
  padding-right: 25px;
}

.search_inputs {
  width: 100%;
  display: flex;

  position: relative;
}

button.mob-createnewBtn {
  display: none;
}

.close-inputBtn,
.save-groupBtn {
  background: transparent;
  display: block;
  border: none;
  outline: none;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}
.search_inputs .close-inputBtn {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.msg-group-info .close-inputBtn {
  top: 20px;
}

.save-groupBtn {
  right: 0;
  top: 0;
}

.msg-heading {
  padding: 0;
  margin: 5px 0;
  font-size: 24px;
  font-weight: 600;
  float: left;
}

.msg-heading.is-admin {
  float: left;
  display: block;
  padding-right: 20px;
  border-right: 1px solid #d8d8d8;
}

.is-admin-header {
  float: left;
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 36px;
  padding-left: 20px;
  font-weight: 600;
  color: #828384;
}
.is-admin-mob {
  display: none;
  width: 100%;
}

.is-admin-mob.is-admin {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  background: #eef0f2;
  width: 100%;
}

.outerMostContainer.non-admin {
  padding-top: 0px !important;
}
.msg-landing {
  display: flex;
  height: 100%;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.mgs-landing_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  max-width: 520px;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}
.mgs-landing_content h3 {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 9px;
}
.mgs-landing_content .landing-img {
  width: 222px;
  height: 222px;
  margin-bottom: 28px;
}

.is-admin-mob .is-admin-header {
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  color: #828384;
}

.is-admin-header .pretty {
  padding-top: 8px;
  padding-left: 10px;
}

.btn {
  font-weight: 500;
  font-family: "Proxima Nova", sans-serif;
  font-size: 13px;
}

a#name-edit,
a#name-edit i {
  color: #ccc;
  font-size: 18px;
  cursor: pointer;
}

a#name-edit {
  margin-left: 10px;
}

a#name-edit .fas {
  vertical-align: top;
}

a#name-edit img {
  vertical-align: baseline;
}

.msg-new-btn,
.msg-btn,
.msg-cancel-btn {
  max-width: 150px;
  width: 100%;
  border: 1px solid #1f802e;
  font-weight: 500;
}

.msg-cancel-btn {
  border: 1px solid #d8d8d8;
  color: #7f7f7f;
  background: #d8d8d8;
  margin-right: 5px;
}

.editButtons {
  min-width: 149px;
  margin-top: 7px;
}

/*editing*/

.msg-users.editing {
  background: #f5f5f5;
}

/*overlay for edit */

.msg-users.mgs-edit-overlay:after {
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9;
}

.editButtons .btn {
  max-width: 72px;
}

.msg-new-group {
  color: #1f802e;
  background: transparent;
  margin-right: 15px;
}

.msg-new-group i {
  color: #1f802e;
}

.msg-header {
  background: #eef0f2;
  padding: 18px 10px;
}

.msg-header .group-titleEdit {
  margin-bottom: 0;
  display: block;
  float: left;
}

.msg-header .group-titleEdit input,
.msg-header .group-titleEdit input:focus {
  margin-bottom: 1px;
  margin-top: 0;
}

.msg-header .group-titleEdit input {
  padding-right: 30px;
}

.msg-header .group-titleEdit button.sendBtn {
  position: absolute;
  right: 0;
  top: 10px;
}

.msg-new-group:hover {
  color: #1f802e;
}

.msg-new-message,
.msg-btn {
  color: #fff;
  background: #1f802e;
}

.msg-new-message i,
.msg-btn i {
  color: #fff;
}

.msg-new-btn i {
  vertical-align: text-bottom;
  font-size: 15px;
  margin-right: 5px;
}

.msg-new-message:hover,
.msg-btn:hover {
  color: #fff;
}

.group-titleEdit .msg-btn {
  max-width: 100%;
  margin-top: 15px;
}

.msg-new-message:hover {
  color: #fff;
}

.applicants_container.applicant_listing,
.resource_container.your_crew_div,
.resource_container.listResourcePage,
.applicants_container.assign_staff_container,
.settings_container {
  margin-top: 60px;
}
.inv-mobile-view #app .outerMostContainer {
  margin-top: 60px !important;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #666666;
  font-size: 14px;
  line-height: normal !important;
  overflow: visible;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666666;
  font-size: 14px;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666666;
  font-size: 14px;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666666;
  font-size: 14px;
  line-height: normal;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #666666;
  font-size: 14px;
  line-height: normal;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #666666;
}

/* top search custom */
/* .search.left input[type=text]::-webkit-input-placeholder,
 .search.left input[type=text]:-moz-placeholder,
 .search.left input[type=text]::-moz-placeholder,
 .search.left input[type=text]:-ms-input-placeholder,
.search.left input[type=text]::-ms-input-placeholder,
.search.left input[type=text]::-ms-input-placeholder,
 {

 } */

.msg-userlist-search input {
  border: none;
  background: transparent;
  min-height: 58px;
  padding-left: 30px;
  /* background-image: url(../images/search.svg); */
  /* background-image: url( https://firestormfire.sfo2.cdn.digitaloceanspaces.com/dev/assets/icons/search-icon.svg); */

  background-repeat: no-repeat;
  background-position: left center;
  width: calc(100% - 40px);
  margin-bottom: 0;
}

input::-ms-clear {
  display: none;
}

.msg-userlist-search input:focus {
  box-shadow: none;
  outline: none;
  background-image: none !important;
  padding-left: 0;
}

.msg-user-list {
  width: 100%;
  max-width: 324px;
  background-color: #f7f9fa;
  border-right: 1px solid #d8d8d8;
}

.msg-lists {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  overflow-y: scroll;
  min-height: calc(100vh - 224px);
  max-height: calc(100vh - 224px);
}

.msg-lists .msg-users,
.group-member-list .msg-users {
  cursor: pointer;
}

.msg-list-section {
  display: block;
  width: 100%;
  float: left;
  border-bottom: 1px solid #d8d8d8;
}
.msg-list-section .msg-users {
  display: flex;
  align-items: center;
}

.msg-list-section.searchlist {
  border: none;
}

.msg-list-section.searchlist .msg-users {
  cursor: pointer;
}

.msg-list-section.searchlist .nav > li {
  position: relative;
  display: block;
  width: 50%;
}

.msg-list-section.searchlist .nav > li a.btn.search-filter,
.group-member-list .nav > li a.btn.search-filter {
  width: 100%;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background: transparent !important;
}

.group-member-list .nav-tabs > li {
  width: 50%;
}

.group-member-list .nav-tabs {
  padding-top: 5px;
  margin-bottom: 5px;
}

.msg-list-section.searchlist .nav-tabs {
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: none;
  margin-bottom: 10px;
}

.msg-section-title {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  color: #9b9b9b;
}

a.msg-admincontrol {
  padding: 0 15px !important;
  margin: 0;
  font-size: 13px;
  color: #000;
  font-weight: 500;
  border-right: 1px solid #e3e3e3;
}

a.msg-admincontrol.user-count {
  cursor: default !important;
}

a.msg-admincontrol.gi-open img {
  vertical-align: middle;
  margin-top: -2px;
}

ul.admincontrols {
  padding-top: 7px;
  padding-bottom: 7px;
}

ul.admincontrols > li {
  width: auto;
  min-height: 20px;
}

ul.admincontrols a {
  cursor: pointer !important;
  background-color: transparent !important;
  box-shadow: none;
  outline: none;
}

ul.admincontrols li:last-child a.msg-admincontrol {
  border-right: none;
}

ul.admincontrols li a.msg-admincontrol:hover {
  background: transparent;
}

.favorite.favorited,
.favorite.favorited i {
  color: #e7c408 !important;
}

.msg-users {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  padding: 13px 25px;
}

.msg-user-pic {
  width: 40px;
  height: 40px;
  display: block;
  float: left;
  position: relative;
}

.msg-users.edit-msg {
  background: #f7f9fa;
}

.msg-user-pic img {
  max-width: 100%;
  border-radius: 100%;
  vertical-align: top;
}

.msg-group-info .msg-user-pic img {
  border: 1px solid #d8d8d8;
}

.msg-messageData {
  width: calc(100% - 40px);
  display: block;
  float: left;
  padding: 0 0 0 15px;
}

.time,
.msg-name {
  display: block;
  float: left;
}

.msg-name {
  padding-right: 10px;
  padding-right: 10px;
  font-weight: 700;
  color: #000;
}

.time,
.msg-preview,
.msg-full {
  color: #7b7c7c;
  font-size: 13px;
}

.msg-preview,
.msg-full {
  font-size: 13px;
}

.msg-name,
.msg-preview {
  max-width: calc(100% - 80px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.msg-full {
  white-space: pre-line;
  text-overflow: unset;
  width: 100%;
  padding-right: 50px;
  display: block;
  float: left;
  color: #000;
  word-break: break-word;
  line-height: 1.6;
}

.msg-preview span.user {
  font-weight: 700;
  margin-right: 5px;
}

.msg-messageheader {
  border-bottom: 1px solid #e3e3e3;
  padding: 13px 12px;
  box-sizing: border-box;
  max-height: 58px;
}

.msg-mob-messageheader {
  display: none;
}

.msg-messageheader h3.title {
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  float: left;
  font-family: "Proxima Nova", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(50% + 15px);
}

h3.title .msg-user-pic {
  width: 32px;
  height: 32px;
  margin-right: 18px;
}

.msg-messageheader h3.title span {
  background: #dbdbdb;
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 100%;
  margin-right: 18px;
  color: #666666;
  font-size: 16px !important;
  vertical-align: top;
}

.msg-messageheader h3.title span i {
  font-size: 16px !important;
  color: #666;
}

.time {
  max-width: 100px;
  font-size: 12px;
  margin-top: 1px;
  text-transform: capitalize;
}

.row.msg-message-section .msg-messageData .time {
  /* font-size: 12.1px; */
  white-space: nowrap;
}

.msg-preview {
  max-width: 100%;
  width: 100%;
}

.msg-selected {
  background: #444444;
}

.msg-selected .msg-name {
  color: #fff;
}

.msg-selected .time,
.msg-selected .msg-preview,
.msg-selected .msg-preview span {
  color: #ededed;
}

.msg-user-pic.has-message:after {
  content: "";
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  border: 2px solid #542412;
  top: -6px;
  left: -6px;
  border-radius: 100%;
}

.msg-user-pic.has-message .msg-count {
  position: absolute;
  top: -9px;
  right: -4px;
  background: #542412;
  color: #fff;
  display: block;
  min-width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 100%;
  z-index: 2;
  font-size: 10px;
  line-height: 16px;
}

#app .outerMostContainer {
  margin-top: 0px !important;
  padding-top: 0 !important;
}

#app .chat-active .outerMostContainer {
  padding-top: 60px !important;
}

/* messages */

.msg-messages {
  width: calc(100% - 324px);
  background: #fff;
  height: 100vh;
}

.msg-messages-list {
  overflow-y: auto;
  position: relative;
  height: 100%;
  max-height: calc(100vh - 360px);
  min-height: 0px;
  /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; */
}
.row.msg-message-section {
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
}

.msg-messages-list.admin-mode {
  max-height: calc(100vh - 168px);
}

.mgs-updated-time {
  width: 100%;
  text-align: center;
  position: relative;
  display: block;
  float: left;
  padding: 10px;
  margin-top: 10px;
}

.col-msg {
  padding-left: 0;
  padding-right: 0;
}

.msg-modify a {
  padding: 0px 4px;
  display: block;
  border: 1px solid #eef0f2;
  border-radius: 3px;
  background: #d8d8d8;
  height: 13px;
  line-height: 10px;
  cursor: pointer;
  border: 1px solid #979797;
}

.member-modify a {
  padding: 0px 4px;
  display: block;
  height: 13px;
  line-height: 10px;
}

.msg-modify a img,
.member-modify a img {
  opacity: 0.55;
}

.msg-modify a img {
  margin-top: -2px;
}

.msg-modify,
.member-modify,
.msg-action {
  position: absolute;
  top: 20px;
  right: 40px;
}

.member-modify {
  right: 20px;
}

.msg-action {
  top: 10px;
  z-index: 10;
}

.group-member-list .msg-action {
  right: 20px;
}

.group-member-list .btn-delete,
.group-member-list .btn-edit {
  padding: 11px 20px;
  min-width: 170px;
  text-align: left;
}

.group-member-list .btn-delete:hover,
.btn-edit:hover {
  background: #f7f9fa;
}

.user_added {
  display: table;
  margin: 0 auto;
  background: #eef0f2;
  padding: 8px 25px;
  border-radius: 50px;
  color: #666666;
}

.user_added span {
  font-weight: 600;
}

.msg-full.edit-mode {
  padding-right: 0;
}

.edit-mode {
  max-width: 100%;
}

#editMsg {
  min-height: 56px;
  padding-right: 80px;
  resize: none;
  margin-bottom: 0;
  margin-top: 10px;
}

#editMsg:focus {
  border: 1px solid #ccc;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

form#editmessage {
  display: block;
  position: relative;
}

form#editmessage button.sendBtn {
  display: block;
}

.edit_emoji {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 10px;
}

.edit_emoji button.sendBtn {
  display: none !important;
}

.edit_emoji button.sendBtn.emoji {
  display: block !important;
  border-right: none;
}

.btn-delete,
.btn-edit {
  border: none;
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  padding: 12px 20px;
  color: #000;
}

span.editedMsg {
  display: block;
  color: #7b7c7c;
  font-size: 13px;
}

.mgs-updated-time span {
  background: #fff;
  padding: 10px 12px;
  color: #666666;
  z-index: 2;
  position: relative;
}

.mgs-updated-time:before {
  content: "";
  position: absolute;
  left: 25px;
  right: 25px;
  height: 1px;
  background: #d8d8d8;
  top: 50%;
  z-index: 1;
}

a.documemt-link,
a.image-link {
  display: flex;
  padding: 14px;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
  max-width: 280px;
  width: 100%;
  margin-top: 15px;
  position: relative;
}
span.doc-details {
  max-width: 180px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a.image-link {
  max-width: 120px;
  padding: 0;
}

a.image-link img.attached-img {
  width: 100%;
  border-radius: 5px;
  opacity: 0.6;
}

a.image-link span.downloadAnim,
a.image-link span.download-complete {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

a.documemt-link:hover {
  text-decoration: none;
  cursor: pointer;
}

a.documemt-link span {
  display: block;
}

span.doc-icon {
  float: left;
  margin-right: 10px;
}

span.doc-details {
  color: #555459;
}

span.doc-size {
  color: #9e9ea6;
}

span.downloadAnim,
span.download-complete {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 34px;
  height: 34px;
}

span.downloadAnim img,
span.download-complete img {
  position: absolute;
  top: 0;
  right: 0;
}

img.closeIco {
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}
img.closeIco.downloadIco {
  width: 20px;
}

.msg-form {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px;
  background: #fff;
  z-index: 3;
}

.msg-input,
.attach-doc {
  display: block;
  float: left;
  background: #f7f9fa;
  border: 1px solid #eaeaea;
}

.msg-input {
  padding: 12px;
  width: 100%;
  max-width: calc(100% - 57px);
  border-radius: 3px;
}

input.messagesend,
textarea.messagesend {
  border: none;
  background: transparent;
  width: calc(100% - 64px);
  padding: 0;
  margin: 0;
  resize: none;
}

input.messagesend:focus,
textarea.messagesend:focus,
button.sendBtn:focus {
  outline: none;
}

button.sendBtn {
  border: none;
  background: transparent;
  float: right;
  color: #cccccc;
  font-size: 16px !important;
  line-height: 0;
}

button.sendBtn i {
  color: #adafaf;
  font-size: 16px !important;
}

.msg-input {
  position: relative;
}

.msg-input button.sendBtn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 24px;
}

button.sendBtn.emoji {
  opacity: 0.3;
  border-right: 1px solid #ccc;
  right: 35px;
}

button.sendBtn.emoji.emoji-added {
  opacity: 0.7;
}

.emojis {
  position: absolute;
  background: #eef0f2;
  top: -55px;
  right: 0;
  min-width: 175px;
  padding: 10px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  z-index: 3;
}

.emojis a {
  margin: 0px 10px;
  cursor: pointer;
}

.emojis:after {
  position: absolute;
  content: "";
  bottom: -17px;
  right: 40px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 22px solid #eef0f2;
}

.edit-mode .emojis:after {
  right: 10px;
}

.emojis .fa-heart,
.msg-full .fa-heart {
  color: #f00;
}

.emojis .fa-smile-beam,
.emojis .fa-frown,
.msg-full .fa-smile-beam,
.msg-full .fa-frown {
  color: #ffb100;
}

.emojis .fa-thumbs-up,
.emojis .fa-thumbs-down,
.msg-full .fa-thumbs-up,
.msg-full .fa-thumbs-down {
  color: #a57f2a;
}

a.attach-doc {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  margin-right: 12px;
  text-align: center;
  font-size: 18px;
  color: #b2b2b2;
  line-height: 2.5;
  position: relative;
}

a.attach-doc input[type="file"] {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  position: absolute;
  border: none;
  opacity: 0;
}

a.attach-doc i {
  font-size: 18px;
  color: #b2b2b2;
}

.search-filterbtn {
  padding: 13px 12px;
  display: block;
  float: left;
  width: 100%;
}

a.btn.search-filter {
  width: 50%;
  border-radius: 0px;
  margin: 0;
  display: block;
  float: left;
  border: 1px solid #d9d9d9;
  color: #b7b7b7 !important;
  background: #eef0f2;
  padding: 10px;
  text-transform: uppercase;
  box-shadow: none !important;
}

a.btn.search-filter.active {
  border-bottom-color: #1f802e;
  color: #686868 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom: 2px solid #1f802e !important;
}

a.btn.search-filter:focus {
  outline: none;
}

/* group info */

.msg-group-info {
  border-left: 1px solid #d8d8d8;
  padding: 25px 0;
  position: relative;
  max-height: calc(100vh - 144px);
  display: none;
  opacity: 0;
  transform: translateX(100%);
  transition: all ease 0.5s;
  background: #fff;
}

.msg-group-info.opened {
  width: 100%;
  max-width: 275px;
  display: block;
  float: left;
  opacity: 1;
  transform: translateX(0);
  animation: gi-open 0.3s forwards;
}

@keyframes gi-open {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.group-info-header {
  font-size: 13px;
  color: #000;
  margin-bottom: 30px;
}

.group-info-header img {
  width: 13px;
  height: 13px;
  margin-top: -3px;
}

.group-titles {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
}

.group-member-list {
  width: 100%;
  float: left;
  min-height: calc(100vh - 390px);
  max-height: calc(100vh - 390px);
  overflow-y: scroll;
}

.msg-new .new-container .group-member-list {
  max-height: max-content;
  overflow: hidden;
  height: 640px;
  max-height: calc(100vh - 335px);
}

.msg-new.new .new-container .group-member-list {
  height: 100vh;
  max-height: calc(100vh - 245px);
}

.msg-new .new-container .group-member-list h3 {
  display: none;
}

.group-member-list .msg-users {
  padding: 0;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.group-member-list .msg-users:after {
  position: absolute;
  content: "";
  right: 15px;
  left: 15px;
  bottom: -1px;
  height: 1px;
  background: #f5f5f5;
}

.new-container .group-member-list .msg-users.selected:after {
  background: transparent;
}

a.groupmember-edit,
a.groupname-edit {
  font-size: 12px;
  color: #9b9b9b;
  cursor: pointer;
}

a.groupmember-edit i,
a.groupname-edit i {
  font-size: 18px;
  color: #9b9b9b;
  vertical-align: baseline;
}

a.groupname-edit img {
  width: 12px;
  vertical-align: baseline;
}

a.memberRemove {
  position: absolute;
  right: 0;
  color: #7f7f7f;
}

.group-titleEdit {
  margin-bottom: 20px;
  position: relative;
}

.group-member-list .msg-name,
.msg-title {
  max-width: 100%;
  width: 100%;
  float: left;
  max-width: 270px;
  cursor: pointer;
}

.msg-addUser {
  padding: 0px 5px;
  position: relative;
  padding-bottom: 20px;
}

.msg-addUser:after {
  position: absolute;
  content: "";
  right: 15px;
  left: 15px;
  bottom: 7px;
  height: 1px;
  background: #f5f5f5;
}

.msg-addUser a img {
  margin-right: 18px;
}

a.add {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

.group-titleEdit .form-control,
.group-titleEdit .form-control:focus {
  border-color: #c7c8cb;
  box-shadow: none;
  -webkit-box-shadow: none;
  height: 35px;
  margin-bottom: 0;
  margin-top: 20px;
}

h3.group-title {
  font-size: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 20px;
  font-weight: 700;
}

h4.group-title {
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 20px;
  font-weight: 700;
}

h3.membercount {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: #9b9b9b;
  margin-bottom: 18px;
  display: flex;
  align-items: flex-end;
}

h3.membercount span {
  font-size: 10px;
  background: #1f802e;
  color: #fff;
  width: 18px;
  height: 18px;
  margin-left: 3px;
  line-height: 19px;
  border-radius: 100%;
  text-align: center;
}

img.group-image {
  display: table;
  float: none;
  margin: 0 auto;
}

.msg-messages.group-info-open .gi-open {
  display: none;
}

.msg-messages.group-info-open {
  max-width: calc(100% - 600px);
}

.msg-users.msg-crew,
.crew-members .msg-users {
  background: #f5f5f5;
}

.crew-members {
  float: left;
  width: 100%;
  display: none;
}

.crew-members.toggled {
  display: block;
}

.group-info-header,
.group-titles,
h3.membercount,
.msg-addUser,
.group-member-list .msg-users {
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
}

.msg-messages.group-info-open a.msg-admincontrol {
  border: none;
}

.groupSave {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;
  border-top: 1px solid #d8d8d8;
  z-index: 2;
}

.groupSave button {
  margin: 0 auto;
  display: table;
}

.groupSave button[disabled] {
  background: #c9c9c9;
  border: 1px solid #c9c9c9;
  color: #000;
}

/* end profile information */

/* profile information */

.row.profileInfo {
  margin-top: 20px;
  margin-bottom: 20px;
}

.profile-pic img {
  border-radius: 100%;
  border: 6px solid #f00;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 140px;
}
.profile-pic img.availability {
  border: 6px solid #51dd76;
}

.profile-pic img.guest-code {
  border: 6px solid #ffb300;
}

.profile-details {
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
  display: block;
  float: left;
  width: 100%;
}

.profile-details .msg-btn {
  width: auto;
}

.profile-tb-btn {
  padding: 8px;
  color: #000;
}

.profile-details p {
  margin: 0;
  float: left;
  max-width: 240px;
  line-height: 36px;
}

.profile-details.has-details {
  background-color: #f7f9fa;
}

/* adding new items: messages/groups */

.msg-new {
  width: 100%;
  position: relative;
}

.msg-new .new-container {
  max-width: 480px;
  width: 100%;
}

.msg-new h3.membercount {
  padding-left: 0;
  padding-right: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
  width: 100%;
}

.msg-new h3.membercount span {
  width: 16px;
  height: 16px;
  line-height: 17px;
}

.msg-new.new {
  padding-top: 30px;
  height: 100vh;
  max-height: calc(100vh - 150px);
}

input#selectUser {
  padding-left: 40px;
  /* background-image: url(/static/media/search.c231b03c.svg); */
  /* background-image: url(https://firestormfire.sfo2.cdn.digitaloceanspaces.com/dev/assets/icons/search-icon.svg); */
  background-repeat: no-repeat;
  background-position: 15px center;
}

input#selectUser:focus {
  background: none !important;
  padding-left: 15px;
}

.add-memberform {
  padding-top: 28px;
  padding-bottom: 28px;
  position: relative;
}

.msg-new.new form#addNewMember {
  padding-top: 0;
  border-top: none;
}

.new-group form#addNewMember {
  border-top: none;
}
.new-group form#addNewMember input#selectUser {
  padding-left: 15px;
}

form#addNewMember {
  padding-top: 20px;
  padding-bottom: 0px;
  border-top: 1px solid #ccc;
}
.new-group form#addNewMember {
  border-top: none;
}

.group-name-form {
  min-height: calc(100vh - 150px);
}
.group-member-list-scroll {
  display: block;
  float: left;
  min-height: calc(100vh - 455px);
  max-height: calc(100vh - 455px);
  overflow-y: scroll;
  width: 100%;
}

.msg-new.new .group-member-list-scroll {
  min-height: calc(100vh - 240px);
  max-height: calc(100vh - 240px);
}

.add-memberform .form-group,
#addNewMember .form-group {
  position: relative;
}

.add-memberform .close-inputBtn,
#addNewMember .close-inputBtn {
  display: block;
  top: 10px;
  right: 10px;
}

.add-memberform .form-control:focus,
#addNewMember .form-control:focus {
  border-color: #1f802e;
  box-shadow: none;
}

.memberAdd,
a.memberAdd,
a.memberRemove,
a.crewMemberToggle {
  position: absolute;
  right: 25px;
  top: 20px;
  color: #9b9b9b;
}

.memberAdd {
  width: 20px;
  height: 20px;
  right: 55px;
}

a.crewMemberToggle.toggled {
  transform: rotate(180deg);
}

a.memberRemove {
  color: #407f38;
}

.memberAdd .pretty {
  margin-right: 0;
  position: static;
}

.memberAdd .pretty .state label:after,
.memberAdd .pretty .state label:before {
  width: 20px;
  height: 20px;
  top: 0;
}

.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: #1f802e !important;
  /* background-image: url('../images/save-tick-white.svg'); */
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMCAYAAABvEu28AAAACXBIWXMAAAsSAAALEgHS3X78AAAAkElEQVQoka2S3Q2AIAyEa+IAjMIobiAjMYojOAIjOIIbnMGAqbX1v0l5oNcP6NEAoA8RicgTUWpfMlyB9NtOvtHDdAAS9hF+gazjUcRWSsgMoKvavIylkIpYA3kAk4B4ruEgC+ZLowmpICnkMK12gPAZaacGBWI9fTdsCeNxCtFc0+wdriCW/bmpGhBvfQ2AFuIuWSRNEnkoAAAAAElFTkSuQmCC");
  background-position: 3px center;
  background-repeat: no-repeat;
  background-size: 10px;
}

h3.new-title {
  font-size: 15px;
  font-weight: 500;
  color: #2c2d30;
  margin-top: 10px;
  margin-bottom: 20px;
  display: none;
}

.new-container .msg-title {
  color: #9b9b9b;
}

.form-control {
  height: 43px;
}

.new-container .group-member-list .msg-users {
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
}

.new-container .group-member-list .msg-users:hover {
  background: #f5f5f5;
}

.new-container .group-member-list .msg-users.selected a.memberAdd {
  color: #1f802e;
}

.col-msg .msg-users {
  padding: 13px 25px;
}

.admin-mode .msg-users:hover {
  background: rgba(68, 68, 68, 0.05);
}

.msg-users.deleted_message {
  background: #f5f5f5;
}

.group-member-added {
  margin-top: 30px;
}

.group-member-added .msg-link {
  margin-top: 10px;
  cursor: pointer;
}

.msg-added-member .msg-messageData {
  padding: 5px 6px;
  position: relative;
}

.msg-added-member {
  background: #f1f1f1;
  float: left;
  padding: 5px;
  border-radius: 180px;
  display: table;
  margin-right: 10px;
  margin-bottom: 10px;
}

.msg-added-member .msg-messageData .msg-name {
  width: 100%;
  max-width: 100%;
  text-overflow: initial;
  padding-right: 30px;
}

.msg-added-member .msg-user-pic {
  width: 30px;
  height: 30px;
}

a.removeMember {
  position: absolute;
  right: -7px;
  top: 2px;
  cursor: pointer;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */

input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */

input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

/* admin mode */

/* admin mode toggler*/

.is-admin-header .pretty.p-switch .state:before {
  height: 10px;
  border: 2px solid #d4d4d4;
  background: #d4d4d4;
  width: 28px;
  top: 2px;
}

.is-admin-header .pretty.p-switch .state label:after {
  background-color: #ffffff !important;
  -moz-transform: none;
  -webkit-transform: none;
  transform: none;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  box-sizing: content-box;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  top: -2px;
}

.is-admin-header .pretty.p-switch.p-fill input:checked ~ .state label:after {
  border: 1px solid #6a8a68;
  left: 12px;
}

.is-admin-header .pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #6a8a68;
  background-color: #1f802e !important;
}

.is-admin-hide {
  display: none;
}

.msg-users.is-admin .msg-user-pic {
  width: 64px;
}

.msg-users.is-admin .msg-user-pic img {
  max-width: 40px;
}

.msg-users.is-admin .msg-messageData {
  width: calc(100% - 64px);
}

.msg-users.is-admin .msg-user-pic.has-message:after {
  display: none;
}

img.img-chat-person {
  position: absolute;
  left: 24px;
  border: 3px solid #f7f9fa;
  box-sizing: content-box;
  top: -3px;
}

/* admin mode toggler end*/

/* customising bootstrap */

.modal-dialog {
  max-width: 380px;
  top: 30%;
  margin: 0 auto;
}

h4.modal-title {
  font-size: 16px;
  color: #000;
  text-align: center !important;
  font-weight: bold;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-header {
  padding-top: 30px;
}

.modal-footer {
  padding-bottom: 30px;
}

.modal-btn-actions {
  display: flex;
  float: none;
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
}

.modal-btn-actions .btn {
  max-width: 100px;
  max-height: 30px;
}

.newmsgMob .modal-header {
  padding-top: 30px;
  padding-bottom: 15px;
  text-align: center;
}
.newmsgMob .modal-content {
  box-shadow: none;
}
.modal-content {
  border: 1px solid #ccc;
}

.newmsgMob .modal-header:last-child {
  padding-bottom: 30px;
  border-top: 1px solid #d8d8d8;
}

.newmsgMob a {
  text-align: center;
  color: #1f802e;
  text-decoration: none;
  font-size: 16px;
}

.newmsgMob a i {
  color: #1f802e;
}

.msg-image {
  width: 100%;
  border-radius: 5px;
  position: relative;
}

.image-container {
  position: relative;
  max-width: 200px;
  margin-top: 10px;
}

.image-container span.downloadAnim {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  right: 50%;
  width: 34px;
  height: 34px;
}

.scroll-spinner {
  position: absolute;
  top: 90px;
  transform: translate(50%, -50%);
  right: 50%;
  width: 34px;
  height: 34px;
  z-index: 9;
}

.doc-icon i {
  font-size: 39px;
}

.doc-icon i.fa-file-pdf {
  color: #da4335;
}

.doc-icon i.fa-file-word {
  color: #da4335;
}

.doc-icon i.fa-file-excel {
  color: #da4335;
}

.doc-icon i.fa-file-powerpoint {
  color: #da4335;
}

.doc-icon i.fa-file-archive {
  color: #da4335;
}

.doc-icon i.fa-file-csv {
  color: #da4335;
}

.doc-icon i.fa-file-audio {
  color: #da4335;
}

.ReactModalPortal
  .ReactModal__Content.ReactModal__Content--after-open.delete-modal-overlay {
  bottom: auto !important;
  top: 50% !important;
  position: absolute;
  height: auto;
  transform: translateY(-50%);
  background: transparent !important;
  border: none !important;
  max-width: 380px;
}

.ReactModal__Content.ReactModal__Content--after-open.delete-modal-overlay:focus,
.ReactModalPortal
  .ReactModal__Content.ReactModal__Content--after-open.newmsgMob:focus,
.ReactModalPortal
  .ReactModal__Content.ReactModal__Content--after-open.delete-modal:focus {
  outline: none;
}

.ReactModalPortal
  .ReactModal__Content.ReactModal__Content--after-open.newmsgMob-overlay {
  bottom: auto !important;
  top: 50% !important;
  position: absolute;
  height: auto;
  transform: translateY(-50%);
  background: transparent !important;
  border: none !important;
  max-width: 380px;
}

.ReactModalPortal
  .ReactModal__Content.ReactModal__Content--after-open.newmsgMob,
.ReactModalPortal
  .ReactModal__Content.ReactModal__Content--after-open.delete-modal {
  top: 50% !important;
  position: absolute;
  height: auto;
  transform: translateY(-50%);
  background: transparent !important;
  border: none !important;
  width: 80%;
  bottom: auto !important;
  max-width: 380px;
}

.ReactModal__Content.ReactModal__Content--after-open.newmsgMob-overlay:focus {
  outline: none;
}

/*popup fix*/
.slide-pane__header,
.slide-pane__content {
  background: #fff;
}

.no-results {
  text-align: center;
  padding: 20px 0px;
}

.search-result-tab {
  position: relative;
}

span.conversation-search-loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 34px;
  height: 34px;
  top: 20px;
}

span.conversation-search-loader.user-search {
  top: 70px;
}

.group-member-list-scroll {
  position: relative;
}

.cert-link {
  color: #1f802e !important ;
  text-decoration: underline;
}

.cert-delete-icon {
  float: right;
}

.cert__btn-wrapper {
  margin: 10px;
}

.cert_header-wrapper {
  /* padding: 6px; */
}

.cert_header-wrapper h4 {
  margin: 15px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.report_tbl_head-cert {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.certificates-list {
  padding: 0 16px 0px 16px;
}

.certificates-table-wrapper {
  height: auto !important;
}

.cert-file-hash {
  word-break: break-all;
  color: #1f802e;
  text-decoration: underline;
}

.cert-name {
  word-break: break-all;
}

.applicant-status-modal-body.scroll-incident {
  max-height: calc(100vh - 200px);
  overflow: auto;
}

@media screen and (max-width: 767px) {
  .ReactModal__Content.ReactModal__Content--after-open.update-applicant-status-modal.incident-center {
    top: 50% !important;
    transform: translateY(-50%);
    margin: 0 auto !important;
  }
}
