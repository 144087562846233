@media print {
  .ViewExpiryAndAssignedDates_container {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
  }
  .ViewExpiryAndAssignedDates_sectionOne {
    flex: 1;
    padding-left: 5px;
    border-top: none !important;
  }
  .ViewExpiryAndAssignedDates_sectionTwo {
    flex: 1;
    border-top: none !important;
  }
}

.ViewExpiryAndAssignedDates_sectionOne {
  text-align: left;
  border-top: 1px solid #eee;
  margin-top: 8px;
}
.ViewExpiryAndAssignedDates_sectionTwo {
  text-align: left;
  border-top: 1px solid #eee;
  margin-top: 8px;
}

.ViewExpiryAndAssignedDates_title {
  padding: 6px 0px;
  font-size: 12px;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}

.unOrdered_list {
  list-style: disc;
}
