/** Proxima Nova **/

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_altbold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/RobotoCondensed-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sintony";
  src: url("../fonts/Sintony-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/*@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaSansRegular Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova_altbold';
    src: url('../fonts/Proxima Nova Bold.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Semibold';
    src: url('../fonts/Mark Simonson - Proxima Nova Semibold-webfont.ttf');
    font-weight: normal;
    font-style: normal;
}*/
