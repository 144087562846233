.equip_resp_h1 {
  float: unset !important;
}

.equip_resp_label {
  font-weight: normal;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  width: 100%;
}

.equip_resp_mt_5 {
  margin-top: 5px;
}
