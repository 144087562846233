.container {
  border-top: 1px solid #d5d8d9;
  margin: 45px 70px 15px;
}

.noteInputContainer {
  padding: 16px 0px;
  padding-top: 24px;
}

.noteItem {
  padding: 16px 0px 14px 0px;
  border-bottom: 1px solid #d5d8d9;
}

.noteItemTitle {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #b1b2b4;
}

div p span {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
}

.noteItemDescription {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #202020;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 20px;
  }
}
