.inline-flex {
  display: flex;
  flex-direction: column;
}

.custom--inline-flex {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .custom--inline-flex .form-group {
    width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .inline-flex {
    flex-direction: row;
    align-items: center;
  }
  .custom--inline-flex {
    align-items: flex-start;
  }
  .custom--inline-flex .form-group {
    /* max-width: 400px; */
    flex: 50%;
  }
  .helper-text {
    margin-top: 10px;
  }
  .helper-text.helper-text--lg {
    margin-top: 0;
    flex: 100%;
  }
}

.helper-text {
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.helper-text span {
  font-size: 22px;
}

.inline-flex.row p {
  margin-bottom: 0;
}

.inline-flex.row .form-group-radio {
  margin-bottom: 30px;
}

.col-sm-6 .datePickerWrap .control-label {
  margin-top: 14px;
  margin-bottom: 14px;
}

.officeBlock .datePickerWrap .control-label {
  margin-top: 0;
}

.officeBlock .control-label {
  margin-top: 0;
  margin-bottom: 14px;
}

.officeBlock .form-control,
.officeBlock input[type="text"] {
  height: 44px;
}

.disclaimer_checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}

.submit_button_wrapper {
  display: flex;
  justify-content: end;
}
