.resource-crew-array img {
  max-width: 100%;
}
#amWrapper .am-wrap-left {
  /* min-width: -webkit-calc( 100% - 450px )!important;
    min-width: -moz-calc( 100% - 450px )!important;
    min-width: calc( 100% - 450px ) !important; */
  min-height: 300px;
  -webkit-box-shadow: inset -18px 0px 30px -10px #c9c9c9;
  -moz-box-shadow: inset -18px 0px 30px -10px #c9c9c9;
  -o-box-shadow: inset -18px 0px 30px -10px #c9c9c9;
  box-shadow: inset -18px 0px 30px -10px #c9c9c9;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  padding-right: 5px;
}
.resource-crew-array {
  width: 140px;
  position: relative;
  background: #e7eae6;
  margin-right: 10px;
  float: left;
  min-width: 140px;
  /*padding: 0 !important;*/
}
.resource-crew-array.reserve-list {
  /*padding-bottom: 100%;*/
  margin-bottom: -100%;
}
.assignstaff_container .inner_head {
  height: 50px;
}

.assignstaff_container .content_div {
  padding-top: 10px;
}

.resource-crew-array h3 {
  margin: 0;
  text-align: center;
  font-size: 14px;
  line-height: 47px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  text-transform: uppercase;
}

.connectedSortable {
  width: 136px;
  min-height: 20px;
  list-style-type: none;
  margin: 0;
  padding: 5px 0 0 0;
  float: left;
}

.resource-crew-array ul {
  border-bottom: 1px solid #a0a0a0;
  margin-bottom: 15px;
  width: 100%;
  min-height: 110px;
}

.resource-crew-array.reserve-list ul {
  min-height: initial;
}

#reserveList .resource-crew-array.reserve-list ul {
  min-height: 50px;
}

.resource-crew-array ul li .empimg-wrap {
  width: 78px;
  height: 78px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 5px;
}
.resource-crew-array ul li {
  cursor: move;
  margin-bottom: 30px;
  border: 0;
  background: none;
}
.resource-crew-array ul li p,
.resource-crew-array ul li span {
  font-size: 10px;
  color: #404040;
  margin: 0;
  text-align: center;
}
.assignstaff_container .content_div {
  padding-top: 10px;
  border-radius: 0;
  box-shadow: 0 0 0 0;
}
.resource-crew-array ul li .emp_name {
  text-transform: capitalize;
}
.resource-crew-array .reserve_img {
  margin-bottom: 20px;
}
#amWrapper .am-wrap-right {
  /* width: 40%; */
  width: 430px;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
}
#amWrapper .am-wrap-right .resourcebox {
  width: 114px;
  width: 114.5px;
  float: left;
  margin: 0 15px 15px 0;
}
#amWrapper .am-wrap-right .resourcebox h3 {
  color: #3f7e39;
  text-align: center;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
#amWrapper .am-wrap-right .resourcebox img.res-thumb {
  display: block;
  width: 100%;
  /*height: auto;
    width: 100px;*/
  height: 110px;
  overflow: hidden;
}
#amWrapper .am-wrap-right .resourcebox .btn-res-assign {
  display: block;
  color: #fff;
  font-size: 11px;
  background: #3f7e39;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
#amWrapper .am-wrap-right .resourcebox .btn-res-assign.active {
  background: #a0a0a0;
  cursor: default;
}
#amWrapper .am-wrap-right .resourcebox .btn-res-assign:before {
  content: "<";
  left: 29px;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.resource-crew-array h4 {
  text-align: center;
  font-size: 14px;
  color: #757575;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.resource-crew-array img.close_btn {
  float: right;
}
.crew_img {
  width: 100%;
  height: 140px;
}
.dr {
  width: 150px;
  float: left;
  /*padding-right: 10px;*/
  margin-right: 30px;
}
.drag_sort_wrap {
  float: left;
  padding-right: 10px;
  margin-right: 30px;
}
/*.manifest_type_form {
    width: 100% !important
}*/

.staff_div img {
  width: 78px;
  height: 78px;
}

/** new **/

.assign_item {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 10px;
  border-bottom: 1px solid #ccc;
  min-height: 225px;
}
.assign_item img {
  width: 78px;
  height: 78px;
  margin-bottom: 10px;
  border-bottom: 3px solid;
  border-color: #51dd76;
  object-fit: cover;
}
.assign_item h3 {
  text-transform: capitalize !important;
}
.assign_item p {
  font-size: 10px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.drag_sort_wrap.am-wrap-right {
  float: right;
  margin: 0;
  box-shadow: -18px 0px 30px -10px #c9c9c9;
  padding: 0 15px;
  /* width: 422px; */
  padding-right: 0;
  /*width: 40%;*/
}
.resourcebox {
  width: 120.5px;
  float: left;
  margin: 0 15px 15px 0;
}
.resourcebox img {
  width: 100%;
  height: 115px;
  object-fit: cover;
}
.resourcebox h3 {
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  height: 20px;
}
.resourcebox .btn-res-assign {
  width: 100%;
  border-radius: 0;
  padding: 2px;
  font-size: 12px;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.remove_assign {
  background: url("../images/close_button.png") no-repeat;
  width: 21px;
  height: 21px;
  float: right;
  background-size: 21px;
  border: 0;
  min-width: 21px;
  padding: 0;
}
.assign_item img.Unavailable {
  width: 78px;
  height: 78px;
  margin-bottom: 10px;
  border-bottom: 3px solid;
  border-color: #dc0223;
}
.assign_item .staff_div {
  position: relative;
  margin-bottom: 20px;
  cursor: move;
}
.assign_item .color_code {
  position: absolute;
  right: 25px;
  top: 5px;
}
.drag_sort_wrap.am-wrap-right .ffList {
  float: left;
  border-bottom: 1px solid #ccc;
}

.AssignStaffPopup .ReactModal__Content.ReactModal__Content--after-open {
  /* width: 80%;
  height: auto; */
  width: 60vw;
  max-width: 90vw;
}

@media screen and (max-width: 1024px) {
  .AssignStaffPopup .ReactModal__Content.ReactModal__Content--after-open {
    width: 80%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .AssignStaffPopup .ReactModal__Content.ReactModal__Content--after-open {
    width: 80%;
    height: auto;
  }
}

.AssignStaffPopup .modal-head {
  height: 50px;
}
.AssignStaffPopup .modal-title {
  float: left;
  display: inline-block;
}
.AssignStaffPopup img.closeModal {
  margin: 0;
}
.AssignStaffPopup .profile_pic {
  width: 150px;
  height: 150px;
  border-bottom: 3px solid;
  border-color: #51dd76;
}
.AssignStaffPopup .profile_pic.Unavailable {
  border-color: #dc0223;
}
.drag_sort_wrap.am-wrap-left {
  /* min-width:-webkit-calc( 100% - 422px )!important;
        min-width:-moz-calc( 100% - 422px )!important;
        min-width:calc( 100% - 422px ) !important;
        width: calc( 100% - 422px ) !important; */
  min-height: 300px;
  -webkit-box-shadow: inset -18px 0px 30px -10px #c9c9c9;
  -moz-box-shadow: inset -18px 0px 30px -10px #c9c9c9;
  -o-box-shadow: inset -18px 0px 30px -10px #c9c9c9;
  box-shadow: inset -18px 0px 30px -10px #c9c9c9;

  padding: 0 10px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  padding-bottom: 1000px;
  /*margin-bottom: -1000px;*/
  margin-right: 0 !important;
}

.AssignStaffPopup .profilePicture {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.staffList {
  display: flex;
  flex-direction: row;
  height: 500px;
}

.lisht {
  display: flex;
  width: 250px;
  background-color: #999;
  margin-right: 4px;
  flex-direction: column;
}

.draggables {
  width: 100%;
}

.inner-p {
  display: flex;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
}

.assign_item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.assign_item .staff_div {
  position: relative;
  margin-bottom: 20px;
  cursor: move;
  width: 100%;
}

.assign_staff_container .relative {
  position: relative;
}
.assign_staff_container .loader-drag {
  background: rgba(255, 255, 255, 0);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  user-select: none;
  cursor: default;
}

.assign_staff_container .assign_item .staff_div {
  min-height: 158px;
}
.assign_staff_container .task-header {
  min-height: 47px;
}
.assign_staff_container .button {
  position: relative;
  z-index: 2;
  min-width: 60px;
}
.assign_staff_container .button.disabled {
  background: #848484 !important;
}
.assign_staff_container .padding-box {
  /* padding-left: 4px; */
}
@media screen and (max-width: 1136px) and (min-width: 600px) {
  .assign_staff_container .drag_sort_wrap.am-wrap-left {
    min-width: calc(100% - 363px) !important;
  }
  .assign_staff_container .custom-css .drag_sort_wrap.am-wrap-right {
    /* width: 363px; max-width: 363px;min-width: 363px;  */
  }
  .assign_staff_container .ffList .resourcebox:nth-child(3n) {
    /* margin-right: 0 ; */
  }

  .assign_staff_container .padding-box {
    display: block;
    width: 100%;
    float: left;
    padding-left: 9px;
  }
}

.assign_staff_container .resourcebox {
  position: relative;
  user-select: none;

  text-align: center;
}
.assign_staff_container .image-wrapper {
  background: #eaeaea;
  text-align: center;
}
.assign_staff_container .image-wrapper img {
  max-width: 97px;
  height: initial !important;
  margin-top: 17px;
}
.assign_staff_container .resourcebox:after {
  position: absolute;
  content: "";
  display: block;
  background: transparent;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  user-select: none;
}

.assign_staff_container .resource-crew-array {
  min-width: 150px;
  text-align: center;
  user-select: none;
}

.assign_staff_container .resource-crew-array img {
  max-width: 77px;
}

.assign_staff_container .dr {
  margin-right: 13px;
}
.assign_staff_container .remove_assign {
  outline: none;
}

/********* Assign Mobile View - START ****************/
.assignStaff_mobile_container .content_div {
  padding: 0;
}
#amWrapperMob .resourcebox {
  float: left;
  width: 100%;
}
#amWrapperMob .crew-div {
  float: left;
  width: 100%;
  padding: 12px 10px 9px 12px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
#amWrapperMob .crew-img {
  width: auto;
}
#amWrapperMob .crew-img {
  /* width: 30%; */
  float: left;
  margin-right: 10px;
}
#amWrapperMob .resourcebox .crew-img img,
#amWrapperMob .crew-list .crew-img img,
#amWrapperMob .step4 .member-list img {
  width: 80px;
  height: 80px;
}
#amWrapperMob .crew-details {
  padding: 17px 0;
  float: left;
  text-align: left;
  width: calc(100% - 120px);
  width: -moz-calc(100% - 120px);
  width: -webkit-calc(100% - 120px);
  width: -o-calc(100% - 120px);
  float: left;
}
#amWrapperMob .crew-details p {
  margin: 0 0 5px;
}
#amWrapperMob .crew-details h3 {
  margin: 0 0 10px;
  line-height: 20px;
  text-align: left;
  color: #3f7e39;
}
#amWrapperMob .resourcebox h3 {
  margin: 0;
  text-align: center;
  color: #3f7e39;
  font-size: 14px;
  /*line-height: 47px;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#amWrapperMob .crew-member-list ul {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 5px;
  float: left;
  margin-bottom: 10px;
  margin-top: 15px;
  -webkit-overflow-scrolling: touch;
}
#amWrapperMob .crew-member-list li {
  width: 140px;
  display: inline-block;
  padding: 0 5px;
  vertical-align: text-top;
  cursor: pointer;
  text-align: center;
}
#amWrapperMob .step4 .crew-img img,
#amWrapperMob .crew-member-list img,
#amWrapperMob .res-selected .crew-img img {
  width: 110px;
  height: 110px;
}
#amWrapperMob .crew-member-list img {
  max-width: 80%;
}
#amWrapperMob .note {
  background: #f7f9fa;
  border: 1px solid #ddd;
  padding: 25px 10px;
  border-right: 0;
  border-left: 0;
  width: 100%;
  border-top: 0;
  float: left;
}
#amWrapperMob .info {
  color: #3f7e39;
  font-size: 15px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  margin-left: 5px;
}
#amWrapperMob .crew-member-list h3 {
  padding-top: 10px;
  line-height: 20px !important;
}
#amWrapperMob .crew-member-list h3 {
  padding-top: 10px;
  line-height: 20px !important;
  height: auto;
}
#amWrapperMob .crew-member-list p {
  font-size: 13px;
  margin: 5px 0;
  white-space: normal;
  word-break: break-all;
}
#amWrapperMob .clear-div {
  padding: 4px 0;
  background: #eee;
  float: left;
  border: 1px solid #ddd;
  width: 100%;
}
#amWrapperMob .crew-div .crew-details h3 {
  text-align: left;
}
#amWrapperMob .position-confirm-div {
  padding: 15px;
  float: left;
  text-align: center;
  width: 100%;
}
#amWrapperMob .step4 .member-list li {
  list-style-type: none;
  float: left;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 12px 0;
}

#amWrapperMob .step4 .member-list ul {
  margin: 0;
  padding: 0 10px;
}
#amWrapperMob .step4 .member-list img {
  float: left;
  margin-right: 10px;
}
#amWrapperMob .step4 .mem-det h3 {
  padding: 0;
  text-align: left;
  line-height: 20px !important;
  font-weight: normal;
}
#amWrapperMob .step4 .qualy {
  margin: 5px 0px;
  word-break: break-all;
}
#amWrapperMob #confirmPlacement {
  margin-bottom: 0;
}
#amWrapperMob .placement-confirm-div {
  float: left;
  width: 100%;
  padding: 15px;
  text-align: center;
}
#amWrapperMob .step4 .member-list li:last-child {
  border-bottom: 0;
}
.step1 #amWrapperMob .note {
  background: #f7f9fa;
  border: 0;
}
#amWrapperMob .crew-div .crew-details .selected {
  float: left;
  margin-right: 5px;
}
#amWrapperMob .top {
  float: left;
  width: 100%;
  padding: 10px;
}
#amWrapperMob .goBack,
#amWrapperMob .cancel {
  display: inline-block;
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: auto;
  color: #3f7e39;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
#amWrapperMob .cancel {
  color: #666;
}
#amWrapperMob .step3 .crew-details {
  padding: 5px 0;
}
/********* Assign Mobile View - ENDS ****************/

.assign_staff_container .outer {
  margin-top: 80px;
  padding: 0 10px;
  display: flex;
}

.assign_staff_container .staffList {
  display: flex;
  flex-direction: row;
  height: 500px;
}

.assign_staff_container .lisht {
  display: flex;
  width: 250px;
  background-color: #999;
  margin-right: 4px;
  flex-direction: column;
}

.assign_staff_container .draggables {
  width: 100%;
}

.assign_staff_container .inner-p {
  display: flex;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
}

.assign_staff_container .assign_item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.assign_staff_container .assign_item .staff_div {
  position: relative;
  margin-bottom: 20px;
  cursor: move;
  width: 100%;
}

.assign_staff_container .resource-crew-array {
  min-width: 150px;
}

.assign_staff_container .custom-css .assign_staff_container {
  width: 100%;
}

.assign_staff_container .custom-css .assign_staff_content {
  display: flex;
}

.assign_staff_container .custom-css .drag_sort_wrap.am-wrap-right {
  /* max-width: 422px;
    min-width: 422px; } */
}

.assign_staff_container .custom-css .inner_box {
  /* padding-right: 51px; */
  width: 100%;
  max-width: 99%;
  float: left;
}

.assign_staff_container .custom-css .drag_sort_wrap.am-wrap-left {
  padding-top: 29px;
}

.assign_staff_container .assign_staff_container {
  width: 100%;
}

.assign_staff_container .bg-fill {
  background-color: #fff;
}

.assign_staff_container .emp_link_resource {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.assign_staff_container .emp_link_resource .list_item {
  line-height: 45px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.assign_staff_container .relative {
  position: relative;
}

.assign_staff_container .loader-drag {
  background: rgba(255, 255, 255, 0);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.assign_staff_container .assign_item .staff_div {
  min-height: 158px;
}

.assign_staff_container .task-header {
  min-height: 47px;
}

.assign_staff_container .button {
  position: relative;
  z-index: 2;
}

.assign_staff_container .button.disabled {
  background: #848484 !important;
}

.assign_staff_container .padding-box {
  /* padding-left: 4px; */
}

@media screen and (max-width: 1136px) and (min-width: 600px) {
  .assign_staff_container .drag_sort_wrap.am-wrap-left {
    min-width: calc(100% - 363px) !important;
  }
  .assign_staff_container .custom-css .drag_sort_wrap.am-wrap-right {
    /* width: 363px;
      max-width: 363px;
      min-width: 363px;  */
  }
  .assign_staff_container .ffList .resourcebox:nth-child(3n) {
    /* margin-right: 0;  */
  }
  .assign_staff_container .padding-box {
    display: block;
    width: 100%;
    float: left;
    padding-left: 9px;
  }
}

.assign_staff_container .resourcebox {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
}

.assign_staff_container .image-wrapper {
  background: #eaeaea;
  text-align: center;
}

.assign_staff_container .image-wrapper img {
  max-width: 97px;
  height: initial !important;
  margin-top: 17px;
}

.assign_staff_container .resourcebox:after {
  position: absolute;
  content: "";
  display: block;
  background: transparent;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.assign_staff_container .resource-crew-array {
  min-width: 150px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.assign_staff_container .resource-crew-array img {
  max-width: 77px;
}

.assign_staff_container .dr {
  margin-right: 13px;
}

.assign_staff_container .remove_assign {
  outline: none;
}

.assign_staff_container .image-wrapper img {
  margin-top: 0;
}
.assign_staff_container img.Unavailable.errorImage {
  position: relative;
  top: 6px;
}

/* .assign_staff_container .custom-css .drag_sort_wrap.am-wrap-right{
    width: 200px !important;
    max-width: 200px !important ;
    min-width: 200px !important ;
}


.drag_sort_wrap.am-wrap-left {
    min-width: calc( 100% - 200px ) !important;
    width: calc( 100% - 200px ) ;
} */

.applicants_container.assign_staff_container .content_div.custom-css {
  padding-right: 0 !important ;
  padding-left: 0 !important ;
}

.assign_staff_container .custom-css .drag_sort_wrap.am-wrap-left {
  display: flex;
  padding-left: 26px;
  flex: auto;
}

.assign_staff_container .custom-css .drag_sort_wrap.am-wrap-right {
  display: flex;
  flex: auto;
  max-width: 422px;
}
@media screen and (min-width: 767px) and (max-width: 1150px) {
  .assign_staff_container .custom-css .drag_sort_wrap.am-wrap-right {
    max-width: 255px !important;
    min-width: 255px !important;
  }
}
.resource-crew-array h3.is-wrap {
  line-height: 1.5;
  white-space: pre-wrap;
}

@media screen and (min-width: 601px) {
  .fl-AssignStaffPopup .ReactModal__Content.ReactModal__Content--after-open {
    height: 70dvh;
    overflow: hidden !important;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 60px;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .content_div {
    float: unset;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile1 {
    width: 100%;
    margin-bottom: 24px;
    padding: 16px 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    float: unset;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile1
    p {
    padding: 4px 8px;
    margin: 0;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2 {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    float: unset;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep {
    border-radius: 4px;
    padding: 16px 0;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep
    h2,
  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep
    h4,
  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep
    h4,
  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep
    p {
    padding: 4px 16px;
    border-radius: 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep
    h2,
  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep
    h4,
  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep
    h4 {
    padding-bottom: 16px;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2
    .profile-sep
    p:last-of-type {
    border: none;
  }
}

@media screen and (min-width: 991px) {
  .fl-AssignStaffPopup .ReactModal__Content.ReactModal__Content--after-open {
    width: 100%;
    max-width: 880px;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body {
    position: relative;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .content_div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile1 {
    width: 30%;
    max-width: 250px;
    position: sticky;
    top: 0;
    left: 0;
  }

  .fl-AssignStaffPopup
    .ReactModal__Content.ReactModal__Content--after-open
    .modal-body
    .profile2 {
    width: auto;
    flex: 1;
  }
}

@media screen and (min-width: 1399px) {
  .fl-AssignStaffPopup .ReactModal__Content.ReactModal__Content--after-open {
    max-width: 1200px;
  }
}
