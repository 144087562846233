@import "../../assets/styles/mixin.scss";

.ft.profile-data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  position: relative;

  @media #{$xl} {
    gap: 24px;
    padding: 24px 0;
  }

  h3,
  h4,
  p,
  span,
  address,
  li {
    font-family: "Montserrat", sans-serif;
    color: #000;
    margin: 0;
    word-break: break-word;
  }

  p,
  span,
  li {
    font-size: 13px;
    font-weight: 500;
    line-height: 153.846%;
    b,
    strong {
      font-weight: 600;
    }
    .is-red {
      color: #ff0000;
    }
  }

  h3 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 12px;
    text-transform: capitalize;
  }

  h4 {
    font-size: 13px;
    font-weight: 600;
    line-height: 153.846%;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .profile-data__nav {
    display: none;
    width: 33.33%;
    max-width: 195px;

    @media #{$xl} {
      display: block;
      margin-right: 105px;
    }
    ul {
      padding-left: 24px;
      padding-top: 24px;
      margin-bottom: 0;
      position: sticky;
      top: 50px;
    }
    li {
      list-style: none;
      position: relative;
      padding: 0;
      border: none;
      &::after {
        content: "";
        width: 2px;
        height: calc(100% - 16px);
        background-color: #000000;
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
      }
      &.active {
        &::after {
          display: block;
        }
      }

      a {
        font-family: "Montserrat";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 153.846%;
        color: #000;
        padding: 5px 0;
        cursor: pointer;
        display: inline-block;
      }
    }
  }

  .profile-data__cnt {
    flex: 1;
    width: 100%;
    @media #{$xl} {
      max-width: calc(100% - 576px);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .profile-data__user,
  .profile-data__drops {
    padding: 0;
    border: 1px #e0dfdc;
    border-radius: 4px;
    background: #fff;

    &#employment-history {
      margin-bottom: 800px;
    }

    header {
      display: block;
      position: relative;
      padding: 16px 16px 12px;

      figure {
        position: relative;
        background-color: #eef0f2;
        border-radius: 50%;
        border: 5px solid #51dd76;
        overflow: hidden;
        --aspect-ratio: 100/100;
        max-width: 105px;
        margin-bottom: 12px;
        
        &.Locked {
          border-color: #a8a8a8;
          filter: grayscale(100%);
        }
        &.Available {
          border-color: #51dd76;
        }
        &.Unavailable {
          border-color: #dc0223;
        }
        &.Guest {
          border-color: #ffb300;
        }

        input {
          cursor: pointer;
          pointer-events: all !important;
        }

        img,
        svg {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          object-fit: cover;
        }
        &:before {
          content: "";
          display: block;
          padding-top: calc(var(--aspect-ratio) * 100%);
        }

        .profile__camera {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          opacity: 0;
          pointer-events: none;
          background-color: rgba(0, 0, 0, 0.749);
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          svg {
            width: 24px;
            height: 24px;
            position: static;
            object-fit: contain;
          }
        }

        &:hover {
          .profile__camera {
            opacity: 1;
          }
        }
      }
    }

    li {
      padding: 16px;
      span {
        display: inline-block;
      }
      & > span {
        flex: 1 130px;
        &:nth-child(1) {
          flex: 1 1 105px;
          max-width: 105px;
          margin-right: 16px;
          @media #{$md} {
            max-width: 160px;
          }
        }
      }
      .profile-edit-button {
        @media #{$xxl} {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }
      }
      &:hover {
        .profile-edit-button {
          // background-color: red;
          opacity: 1;
          visibility: visible;
          pointer-events: all;

          svg {
            path {
              stroke: #000;
            }
          }
        }
      }
      address {
        flex: 1;
      }
      & > ul {
        flex: 1;
        & > li {
          &:nth-child(1) {
            border-top: 0;
            padding-top: 0;
          }
        }
      }
      &:has(> ul) {
        position: relative;
        @media #{$xxl-l} {
          display: flex;
          flex-direction: column;
        }
        & > span {
          &:nth-child(1) {
            @media #{$xxl-l} {
              flex: 1;
            }
          }
        }
        & > ul {
          flex: 1;
          width: 100%;
          @media #{$xxl-l} {
            padding-top: 16px;
          }
          & > li {
            padding-left: 0;
            &:last-child {
              padding-bottom: 0;
              border-bottom: none;
            }
          }
        }
        .profile-edit-button {
          @media #{$xxl-l} {
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }
      }
    }

    &-title {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 12px;
      h3 {
        position: relative;
        padding-right: 6px;
        padding-left: 14px;
        flex: 1;
        margin: 0;
        &::after {
          content: "";
          position: absolute;
          top: 3px;
          left: 0;
          width: 10px;
          height: 10px;
          background-color: #ffffff;
          border-radius: 50%;
          overflow: hidden;
        }
        &.Blue {
          &::after {
            background-color: #3727D0;
          }
        }
        &.Red {
          &::after {
            background-color: #dc0223;
          }
        }
        &.Yellow {
          &::after {
            background-color: #e6e432;
          }
        }
      }
    }
  }

  .profile-data__user {
    .profile-data__user-title {
      justify-content: space-between;
      .profile-edit-button {
        @media #{$xl} {
          opacity: 0;
          pointer-events: none;
        }
      }
      
      &:hover {
        .profile-edit-button {
            opacity: 1;
            pointer-events: all;
        }
      }
    }
  }

  .profile-data__note {
    padding: 20px 16px;
    header {
      padding: 0;
      margin-bottom: 8px;
      @media #{$md} {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 11px;
      }
    }
    h4 {
      margin-bottom: 0;
      padding-right: 6px;
    }
    &-dates {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        display: inline-block;
        font-size: 12px;
        line-height: 133.333%;
        margin-right: 3px;
        opacity: 0.6;
      }
    }
  }

  .profile-data__col {
    display: flex;
    flex-direction: column;
    gap: 24px;
    & > header {
      margin: 24px 0 0;
      display: flex;
      button {
        font-size: 13px;
        font-weight: 600;
        color: #3f7e39;
        border: none;
        outline: none;
        background-color: transparent;
        margin-left: auto;
        text-transform: capitalize;
        box-shadow: none;
      }
    }

    &.is-all-active {
      .profile-data__drops {
        .profile-data__drops-cnt {
          display: block;
        }
      }
    }
  }

  .profile-data__drops {
    & > header {
      padding: 0 16px;
      button {
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        padding: 16px 20px 16px 0;
        margin: 0;
        width: 100%;
        position: relative;
        h3 {
          text-align: left;
          margin-bottom: 0;
        }
        &::after {
          content: "";
          position: absolute;
          top: 14px;
          right: 0;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M5 12.5L10 7.5L15 12.5' stroke='black' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          width: 20px;
          height: 20px;
          transform: rotateZ(180deg);
        }
      }
    }
    .profile-data__drops-cnt, .profile-data__footer {
      display: none;
    }
    &.is-active {
      & > header {
        button {
          &::after {
            transform: rotateZ(0);
          }
        }
      }
      .profile-data__drops-cnt, .profile-data__footer {
        display: block;
      }
    }

    & > footer {
      padding: 16px;
      button {
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0 0 20px;
        margin: 0;
        position: relative;
        font-family: "Montserrat";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        color: #3f7e39;
        line-height: 153.846%;
      }
      display: inline-block;
    }

    &--notes {
      .profile-data__note {
        padding: 0;
      }
      .profile-data__drops-cnt {
        & > ul {
          & > li {
            &:nth-child(1) {
              display: flex;
              border-top: 0px;
            }
          }
        }
      }

      & > footer {
        display: none;
      }

      &.is-active {
        & > footer {
          display: flex;
        }
      }
    }

    &#workers-compensation {
      .react-datepicker-wrapper {
        max-width: 50%;
      }
    }
  }

  .profile-data__form-wrap {
    padding: 0 16px;

    &.is-active {
      footer {
        display: flex;
      }
    }

    textarea {
      font-family: "Montserrat";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 176.923%;
      color: #000;
      padding: 10px 12px;
      margin-bottom: 0;
      resize: none;
      &::placeholder {
        color: #5b5b5b;
      }
    }

    footer {
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding: 5px 0;
      display: none;
    }

    .l-btn {
      padding: 6px 20px;
      border-radius: 4px;
      background: #3f7e39;
      color: #fff;
      font-family: "Montserrat";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 142.857%;
      display: inline-block;

      &--close {
        background-color: #747474;
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .link-green {
    font-family: "Montserrat";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 153.846%;
    color: #3f7e39;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .profile-data__table {
    max-width: 100%;
    overflow: auto;
    table {
      width: 100%;
      min-width: 576px;
      thead,
      tbody,
      tfoot {
        width: 100%;
      }

      thead {
        tr {
          th,
          td {
            background-color: #f0f0f0;
          }
        }
      }
      tbody {
        tr {
          td {
            background-color: #ffffff;
          }
          &:nth-child(even) {
            td {
              background-color: #f6f6f6;
            }
          }
        }
      }
      tfoot {
        tr {
          td,
          th {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #000;
            background-color: #ffffff;
          }
          th {
            text-align: right;
          }
        }
      }
    }
    table,
    th,
    td {
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Montserrat";
      border: 1px solid #e0dfdc;
      border-collapse: collapse;

      &.is-red {
        color: #ff0000;
      }
    }

    th,
    td {
      padding: 14px 16px;
    }
  }
  .profile-data__table-text {
    padding: 16px;
  }

  .profile-data__card-wrap {
    display: none;
    @media #{$xl} {
      display: block;
      flex: 1;
    }
    position: relative;
    padding-top: 80%;
    .profile-data__card {
      position: sticky;
      top: 160px;
      padding: 24px 16px;
      border-radius: 4px;
      border: 1px solid #e0dfdc;
      background: #fff;

      h3 {
        width: 100%;
        padding: 0 24px;
        text-align: center;
      }

      figure {
        position: relative;
        background-color: #eef0f2;
        border-radius: 50%;
        border: 5px solid #eef0f2;
        overflow: hidden;
        --aspect-ratio: 100/100;
        max-width: 105px;
        margin: 0 auto 12px;

        &.Locked {
          border-color: #a8a8a8;
        }
        &.Available {
          border-color: #51dd76;
        }
        &.Unavailable {
          border-color: #dc0223;
        }
        &.Guest {
          border-color: #ffb300;
        }

        img,
        svg {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          object-fit: cover;
        }
        &:before {
          content: "";
          display: block;
          padding-top: calc(var(--aspect-ratio) * 100%);
        }
      }
      li {
        border: none;
        color: rgba(0, 0, 0, 0.6);
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 133.333%;
        justify-content: center;
        padding: 8px 0 0;
        span {
          color: inherit;
          font-size: inherit;
          display: inline-block;
          padding-left: 4px;
          max-width: 50%;
        }
      }
    }
  }
}
.profile-edit-button {
  font-size: 0;
  line-height: 0;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  svg,
  img {
    width: 100%;
    max-width: 14px;
    height: auto;
  }
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}
.footer-border {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

// InventoryModal styles
.ReactModal__Content--after-open.update-applicant-status-modal.fl-inventory-modal {
  top: 50% !important;
  right: unset !important;
  bottom: unset !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: calc(100vw - 40px) !important;
  max-width: 910px !important;

  .modal-head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: inline-block;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .profile-data__table {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;

    table {
      min-width: 830px;
      border: 1px solid #E0DFDC;

      thead {
        tr {
          background-color: #F0F0F0;
        }

        th {
          color: #000;
          font-family: "Montserrat", sans-serif;
          font-size: 13px;
          font-weight: 600;
          text-align: center;
          background-color: transparent;
        }
      }
      
      th,
      td {
        padding: 14px 16px;
        border: 1px solid #E0DFDC;
        border-collapse: collapse;
      }

      tr {
        background-color: #fff;

        &:nth-of-type(even) {
          background-color: #F6F6F6;
        }
      }

      td {
        font-size: 13px;
        text-align: left;

        &.not-acknowledged {
          color: #FF0000;
        }
      }

      tfoot {
        tr {
          background-color: #fff;
        }

        td,
        th {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          text-align: right;
          background-color: transparent;
        }

        td {
          width: 71px;
        }
      }
    }
  }

  .profile-data__table-text {
    font-size: 13px;
    line-height: 23px;

    @media #{$lg} {
      max-width: 55%;
    }
  }
}

td.not-acknowledged, .profile-data li span.expired {
  color: #FF0000;
}